import { toNumber, round } from 'lodash';
import FinancialMonthMap from './FinancialMonthMap.js';

export default class FinancialYear {
  /**
   *
   * @param {{
   *  performanceTargetYear: import('../target/PerformanceTargetYear.js').default,
   *  brandingTargetYear: import('../target/BrandingTargetYear.js').default,
   *  outcomeYear: import('../outcome/OutcomeYear.js').default,
   *  spendYear: import('../spend/SpendYear.js').default,
   *  yearOrderNumber: Number|String,
   * }} data
  */
  constructor(data) {
    this.performanceTargetYear = data.performanceTargetYear;
    this.brandingTargetYear = data.brandingTargetYear;
    this.outcomeYear = data.outcomeYear;
    this.spendYear = data.spendYear;
    this.yearOrderNumber = toNumber(data.yearOrderNumber);
    this.months = new FinancialMonthMap({
      performanceTargetMonths: this.performanceTargetYear.getMonths(),
      brandingTargetMonths: this.brandingTargetYear.getMonths(),
      outcomeMonths: this.outcomeYear.getMonths(),
      spendMonths: this.spendYear.getMonths(),
    });
  }

  /**
   *
   * @returns { Number } year order number
  */
  getOrderNumber() {
    return this.yearOrderNumber;
  }

  /**
   *
   * @returns { Number } actual outcome
   */
  getActualOutcome() {
    return this.outcomeYear.getActualOutcome();
  }

  /**
   *
   * @returns { Number } outcome from analytics(Google Analytics)
   */
  getAnalyticsOutcome() {
    return this.outcomeYear.getAnalyticsOutcome();
  }

  /**
   *
   * @returns { Number }
   */
  getPerformanceTarget() {
    return this.performanceTargetYear.getTarget();
  }

  /**
   *
   * @returns { Boolean }
   */
  isCompleteOutcome() {
    return this.outcomeYear.isCompleteOutcome();
  }

  getMonth(monthOrderNumber) {
    const normalizedMonthOrderNumber = toNumber(monthOrderNumber);
    return this.months.getMonth(normalizedMonthOrderNumber);
  }

  getCurrentMonth() {
    return this.months.getCurrentMonth();
  }

  getMonths() {
    return this.months;
  }

  isPerformanceTargetDefined() {
    return this.performanceTargetYear.isComplete();
  }

  getOutcomeToDate() {
    return this.outcomeYear.getOutcomeToDate();
  }

  getPerformanceTargetDeviation() {
    const target = this.performanceTargetYear.getTarget();
    const targetUpToDate = this.performanceTargetYear.getTargetUpToDate();
    const outcome = this.outcomeYear.getActualOutcome();

    const difference = targetUpToDate - outcome;

    return round((difference / target) * 100, 1);
  }

  isOutOfTarget() {
    return this.getPerformanceTargetDeviation() < 0;
  }

  get performanceTarget() {
    return this.performanceTargetYear;
  }

  get brandingTarget() {
    return this.brandingTargetYear;
  }

  get outcome() {
    return this.outcomeYear;
  }

  get spend() {
    return this.spendYear;
  }
}
