import { toNumber, toLower } from 'lodash';
import { join } from 'path-browserify';

export default class Channel {
  /**
   * Constructs a new instance of the class.
   *
   * @param { object } data - The data object used to initialize the instance.
   * @param { number } data.id - The ID of the channel.
   * @param { string } data.name - The name of the channel.
   * @param { Object[] } data.accounts - The accounts of the channel.
   * @param { Object[] } data.airbyteConnectionDestinations - The airbyte connection destinations of the channel.
   */
  constructor(data = {}) {
    this.id = toNumber(data.id);
    this.name = data.name;
    this.accounts = data.accounts || [];
    this.airbyteConnectionDestinations = data.airbyteConnectionDestinations || [];
  }

  /**
   * Get the ID of the channel.
   *
   * @return { number } The ID of the channel.
   */
  getId() {
    return this.id;
  }

  /**
   * Get the name of the channel.
   *
   * @return { string } The name of the channel.
   */
  getName() {
    return this.name;
  }

  /**
   * Retrieves the channel's accounts.
   *
   * @return { import('./Account.js').default[] } An array of Account objects.
   */
  getAccounts() {
    const Account = require('./Account.js').default;
    return this.accounts.map((account) => new Account(account));
  }

  /**
   * Retrieves the Airbyte connection destinations.
   *
   * @return { import('./AirbyteDestination.js').default[] } An array of AirbyteDestination.
   */
  getAirbyteConnectionDestinations() {
    const AirbyteDestination = require('./AirbyteDestination.js').default;
    return this.airbyteConnectionDestinations.map((destination) => new AirbyteDestination(destination));
  }

  isGoogleAds() {
    return toLower(this.getName()) === 'google ads';
  }

  isFacebookAds() {
    return toLower(this.getName()) === 'facebook';
  }

  isGoogleAnalyticsUA() {
    return toLower(this.getName()) === 'google analytics (ua)';
  }

  isGoogleAnalyticsGA4() {
    return toLower(this.getName()) === 'google analytics (ga4)';
  }

  isTikTok() {
    return toLower(this.getName()) === 'tiktok';
  }

  isMerchantCenter() {
    return toLower(this.getName()) === 'merchant center';
  }

  isPinterest() {
    return toLower(this.getName()) === 'pinterest';
  }

  isMicrosoftBing() {
    return toLower(this.getName()) === 'microsoft bing';
  }

  /**
   * Retrieves the icon path based on the current ad platform.
   *
   * @return { string } The path to the icon file.
   */
  getIconPath() {
    const folderPath = '/media/svg/social-logos';
    if (this.isGoogleAds()) {
      return join(folderPath, 'google_1.svg');
    }
    if (this.isFacebookAds()) {
      return join(folderPath, 'facebook_1.svg');
    }
    if (this.isGoogleAnalyticsUA()) {
      return join(folderPath, 'google_analytics.svg');
    }
    if (this.isGoogleAnalyticsGA4()) {
      return join(folderPath, 'google_analytics_ga4.svg');
    }
    if (this.isTikTok()) {
      return join(folderPath, 'tiktok.svg');
    }
    if (this.isMerchantCenter()) {
      return join(folderPath, 'merchant_center.svg');
    }
    if (this.isPinterest()) {
      return join(folderPath, 'pinterest.svg');
    }
    if (this.isMicrosoftBing()) {
      return join(folderPath, 'microsoft_bing.svg');
    }
    return '';
  }
}
