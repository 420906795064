import { toNumber } from 'lodash';
import SpendMonthMap from './SpendMonthMap.js';

export default class SpendYear {
  /**
   *
   * @param { Object } data spend year data
   * @param { Number|String } data.year year order number
   * @param { Number|String } data.target year spend target
   * @param { Number|String } data.spend year spend
   * @param { Number|String } data.googleSpend year google spend
   * @param { Number|String } data.facebookSpend year facebook spend
   * @param { Array } data.months year spend months
   */
  constructor(data) {
    this.year = toNumber(data.year);
    this.target = toNumber(data.target || 0);
    this.spend = toNumber(data.spend || 0);
    this.googleSpend = toNumber(data.googleSpend || 0);
    this.facebookSpend = toNumber(data.facebookSpend || 0);
    this.months = new SpendMonthMap(data.months);
  }

  /**
   * @returns { Number } year ourder number
   */
  getYearOrderNumber() {
    return this.year;
  }

  /**
   * @returns { Number } year spend
   */
  getSpend() {
    return this.spend;
  }

  /**
   * @returns { Number } year target
   */
  getTarget() {
    return this.target;
  }

  /**
   * @returns { Number } year google spend
   */
  getGoogleSpend() {
    return this.googleSpend;
  }

  /**
   * @returns { Number } year facebook spend
   */
  getFacebookSpend() {
    return this.facebookSpend;
  }

  /**
   *
   * @returns { SpendMonthMap }
   */
  getMonths() {
    return this.months;
  }

  /**
   *
   * @returns { Number } year deviation in percentages
   */
  getDeviationInPercentages() {
    return this.target === 0 ? 0 : (this.spend / this.target) * 100;
  }
}
