export default {
  state: {
    classes: {},
  },
  getters: {
    classes(state) {
      return (position) => (position
        ? state.classes[position]
        : state.classes);
    },
  },
  mutations: {
    appendBreadcrumb(state, payload) {
      const { position, className } = payload;
      if (!state.classes[position]) {
        state.classes[position] = [];
      }
      state.classes[position].push(className);
    },
  },
  actions: {
    addBodyClassName(_context, className) {
      document.body.classList.add(className);
    },
    removeBodyClassName(_context, className) {
      document.body.classList.remove(className);
    },
    addBodyAttribute(_context, payload) {
      const { qulifiedName, value } = payload;
      document.body.setAttribute(qulifiedName, value);
    },
    removeBodyAttribute(_context, payload) {
      const { qulifiedName } = payload;
      document.body.removeAttribute(qulifiedName);
    },
    addClassName({ commit }, payload) {
      commit('appendBreadcrumb', payload);
    },
  },
};
