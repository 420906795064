import _ from 'lodash';
import dayjs from 'dayjs';
import Channel from './Channel.js';
import Source from './AIrbyteSource.js';

export default class Account {
  /**
   * @typedef {{
   *  id: Number,
   *  uniqId: Number,
   *  name: String,
   *  status: ('active'|'paused'|'deleted'),
   *  projectId: Number|Null,
   *  project: import('./Project.js')|Null,
   *  channelId: Number|Null,
   *  channel: Object|Null,
   *  createdAt: String,
   *  updatedAt: String|Null,
   *  managedBy: String|Null,
   *  teams: Array<import('./Team.js').default>|Null,
   *  airbyteSources: Array<Object>|Null
   *  type: string,
   * }} Acc
   */
  /**
   *
   * @param { Object } [data={}] account data
   * @param { Acc['id'] } data.id id
   * @param { Acc['uniqId'] } data.uniqId uniq id
   * @param { Acc['name'] } data.name name
   * @param { Acc['status'] } data.status status
   * @param { Acc['projectId'] } data.projectId account project id
   * @param { Acc['project'] } data.project account project
   * @param { Acc['channelId'] } data.channelId account channel id
   * @param { Acc['channel'] } data.channel account channel
   * @param { Acc['createdAt'] } data.createdAt created date
   * @param { Acc['updatedAt'] } data.updatedAt last update date
   * @param { Acc['managedBy'] } data.managedBy management
   * @param { Acc['airbyteSources'] } data.airbyteSources ayrbyte sources data
   * @param { Acc['type'] } data.type account type
   */

  // @ts-ignore
  constructor(data = {}) {
    this.id = data.id;
    this.uniqId = _.toNumber(data.uniqId);
    this.name = data.name;
    this.status = data.status;
    this.projectId = _.toNumber(data.projectId);
    this.project = data.project || {};
    this.channelId = _.toNumber(data.channelId);
    this.channel = data.channel || {};
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.management = data.managedBy;
    this.airbyteSources = data.airbyteSources || [];
    this.type = data.type;
  }

  /**
   * Get account id
   *
   * @returns { Acc['id'] } account id
   */
  getId() {
    return this.id;
  }

  /**
   * Get account uniqid
   *
   * @returns { Acc['uniqId'] } account uniq id
   */
  getUniqId() {
    return this.uniqId;
  }

  /**
   * Get account name
   *
   * @returns { Acc['name'] } account name
   */
  getName() {
    return this.name;
  }

  /**
   * Get account status
   *
   * @returns { Acc['status'] } account status
   */
  getStatus() {
    return this.status;
  }

  /**
   * Get account project id
   *
   * @returns { Acc['projectId'] } account project id
   */
  getProjectId() {
    return this.projectId;
  }

  /**
   * Get account project
   *
   * @returns { import('./Project.js').default } account project
   */
  getProject() {
    const Project = require('./Project.js').default;
    return new Project(this.project);
  }

  /**
   * Get channel id
   *
   * @returns { Acc['channelId'] } account channel id
   */
  getChannelId() {
    return this.channelId;
  }

  /**
   * Get account channel
   *
   * @returns { Channel } account channel
   */
  getChannel() {
    return new Channel(this.channel);
  }

  /**
   * Get date of creation
   *
   * @returns { import('dayjs').Dayjs|null } created date
   */
  getDateOfCreating() {
    return this.createdAt ? dayjs(this.createdAt) : null;
  }

  /**
   * Get daet of updating
   * @returns { import('dayjs').Dayjs|null } last update date
   */
  getDateOfUpdating() {
    return this.updatedAt ? dayjs(this.createdAt) : null;
  }

  /**
   * Get account management type
   *
   * @returns { Acc['managedBy'] } account management
   */
  getManagement() {
    return this.management;
  }

  /**
   * Get account airbyte sources
   *
   * @returns { Source[] } airbyte account sources
   */
  getAirbyteSources() {
    return this.airbyteSources.map((source) => new Source(source));
  }

  /**
   * Check if the account has any Airbyte sources.
   *
   * @return {boolean } Returns true if the account has Airbyte sources, otherwise false.
   */
  hasAirbyteSources() {
    return this.airbyteSources.length > 0;
  }

  /**
   * Get account type.
   *
   * @return { string } account type
   */
  getType() {
    return this.type;
  }

  /**
   * Checks if the account status is 'active'.
   *
   * @return { boolean } Returns true if the status is 'active', otherwise false.
   */
  isActive() {
    return this.status === 'active';
  }

  /**
   * Checks if the status of the account is 'paused'.
   *
   * @return { boolean } Returns true if the status is 'paused', otherwise false.
   */
  isPaused() {
    return this.status === 'paused';
  }

  /**
   * Checks if the status of the account is 'deleted'.
   *
   * @return { boolean } Returns true if the status is 'deleted', otherwise false.
   */
  isDeleted() {
    return this.status === 'deleted';
  }
}
