import { toNumber } from 'lodash';
import dayjs from 'dayjs';
import SpendYear from './ChannelSpendYear.js';

export default class ChannelSpend {
  years = new Map();

  /**
   *
   * @param {{ accountId: Number|String, spend: Array }} spendData spenddata
   */
  constructor({ accountId, spend = [] }) {
    this.accountId = toNumber(accountId);
    this.years = new Map(spend.map((year) => ([toNumber(year.year), new SpendYear(year)])));
  }

  /**
   * Get account id
   *
   * @returns { Number } project id
   */
  getAcconutId() {
    return this.accountId;
  }

  /**
   *
   * @param { Number|String } yearOrderNumber year order number, e.g. 2022
   * @returns { SpendYear } spend year of given year order number
   */
  getYear(yearOrderNumber) {
    return this.years.get(toNumber(yearOrderNumber)) || new SpendYear({ year: yearOrderNumber });
  }

  /**
   *
   * @param { Object } data year data
   * @returns { undefined }
   */
  setYear(data) {
    if (data) {
      this.years.set(toNumber(data.year), new SpendYear(data));
    }
  }

  /**
 * Returns an array of spend years.
 *
 * @returns { SpendYear[] } - Array of spend years.
 */
  getYearList() {
    return [...this.years.values()];
  }

  /**
   * Retrieves the spend for the current year.
   *
   * @return { Number } The spend for the current year.
   */
  getCurrentYearSpend() {
    return this.getYear(dayjs().get('year')).getSpend();
  }

  /**
   * Retrieves the current year.
   *
   * @return { SpendYear } The current year.
   */
  getCurrentYear() {
    return this.getYear(dayjs().get('year'));
  }
}
