<template>
  <Suspense>
    <router-view />
  </Suspense>
</template>

<script>
import { defineComponent, nextTick, onMounted } from 'vue';
import { initializeComponents } from './core/plugins/keenthemes';

export default defineComponent({
  name: 'App',
  setup() {
    onMounted(() => {
      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
</script>

<style lang='scss'>
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~apexcharts/dist/apexcharts.css';
@import '~quill/dist/quill.snow.css';
@import '~animate.css';
@import '~sweetalert2/dist/sweetalert2.css';
@import '~nouislider/dist/nouislider.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~socicon/css/socicon.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~dropzone/dist/dropzone.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~element-plus/dist/index.css';

// Main demo style scss
@import 'assets/sass/plugins';
@import 'assets/sass/style';

@import '~flatpickr/dist/flatpickr.min.css';
@import '~flatpickr/dist/plugins/monthSelect/style.css';
// Dark mode demo style scss
//@import 'assets/sass/plugins.dark';
//@import 'assets/sass/style.dark';

//RTL version styles
//@import 'assets/css/style.rtl.css';
</style>
