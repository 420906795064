import { toNumber, cloneDeep } from 'lodash';
import Channel from '../../core/models/Channel.js';
import { channelRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    channels: new Map(),
  },
  getters: {
    getChannels: (state) => () => [...state.channels.values()].map((channel) => new Channel(cloneDeep(channel))),
    getChannelById: (state) => (id) => {
      const channel = state.channels.get(toNumber(id)) || { id };
      return new Channel(cloneDeep(channel));
    },
  },
  mutations: {
    setChannels(state, channels) {
      state.channels = new Map(channels.map((channel) => [toNumber(channel.id), channel]));
    },
    addChannel(state, data) {
      state.channels.set(toNumber(data.id), data);
    },
    deleteChannelById(state, id) {
      state.channels.delete(toNumber(id));
    },
    clearStore(state) {
      state.channels.clear();
    },
  },
  actions: {
    async loadChannels({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getChannels(), { related: true, ...params });
      commit('setChannels', data.data);
    },
    async loadChannelById({ commit }, { id, params = {} }) {
      const { data } = await apiService.get(apiRoutes.getChannelById(id), { related: true, ...params });
      commit('addChannel', data.data);
    },
  },
});
