import { toNumber, cloneDeep } from 'lodash';
import Currency from '../../core/models/Currency.js';
import { currencyRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    currencies: new Map(),
  },
  getters: {
    getCurrencies: (state) => () => [...state.currencies.values()].map((currency) => new Currency(cloneDeep(currency))),
    getCurrencyById: (state) => (id) => {
      const currency = state.currencies.get(toNumber(id)) || { id };
      return new Currency(cloneDeep(currency));
    },
  },
  mutations: {
    setCurrencies(state, currencies) {
      state.currencies = new Map(currencies.map((currency) => [toNumber(currency.id), currency]));
    },
    addCurrency(state, currency) {
      state.currencies.set(toNumber(currency.id), currency);
    },
    deleteCurrencyById(state, id) {
      state.currencies.delete(toNumber(id));
    },
    clearStore(state) {
      state.currencies.clear();
    },
  },
  actions: {
    async loadCurrencies({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getCurrencies(), { related: true, ...params });
      commit('setCurrencies', data.data);
    },
    async loadCurrencyById({ commit }, { id, params = {} }) {
      const { data } = await apiService.get(apiRoutes.getCurrencyById(id), { related: true, ...params });
      commit('addCurrency', data.data);
    },
  },
});
