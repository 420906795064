import { toNumber, cloneDeep } from 'lodash';
import Role from '../../core/models/Role.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    roles: new Map(),
  },
  getters: {
    getRoles: (state) => () => [...state.roles.values()].map((role) => new Role(cloneDeep(role))),
    getRoleById: (state) => (id) => {
      const role = state.roles.get(toNumber(id)) || { id };
      return new Role(cloneDeep(role));
    },
  },
  mutations: {
    setRoles(state, roles) {
      state.roles = new Map(roles.map((role) => [toNumber(role.id), role]));
    },
    addRole(state, role) {
      state.roles.set(toNumber(role.id), role);
    },
    deleteRole(state, id) {
      state.roles.delete(toNumber(id));
    },
    clearStore(state) {
      state.roles.clear();
    },
  },
  actions: {
    async loadRoles({ commit }) {
      const { data } = await apiService.get('/roles', { related: true, access: true });
      commit('setRoles', data.data);
    },
    async loadRoleById({ commit }, id) {
      const { data } = await apiService.get(`/roles/${id}`, { related: true, access: true });
      commit('addRole', data.data);
    },
  },
});
