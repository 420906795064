import DataUtil from './DataUtil.js';
import { getUniqueIdWithPrefix } from './typesHelpers.js';

export default class EventHandlerUtil {
  static store = new Map();

  static #setEventMetasByName(name, metas) {
    this.store.set(name, metas);
  }

  static #getEventMetasByName(name) {
    return this.store.get(name);
  }

  static #setEventMetaByNameAndHandlerId(name, handlerId, meta) {
    let metas = this.#getEventMetasByName(name);
    if (!metas) {
      metas = new Map();
    }

    metas.set(handlerId, meta);
    this.#setEventMetasByName(name, metas);
  }

  static #getEventsMetaByHandlerId(name, handlerId) {
    const metas = this.store.get(name);
    if (!metas) {
      return;
    }

    return metas.get(handlerId);
  }

  static #setFiredByNameAndHandlerId(name, handerId, fired) {
    const meta = this.#getEventsMetaByHandlerId(name, handerId);
    if (!meta) {
      return;
    }

    meta.fired = fired;
    this.#setEventMetaByNameAndHandlerId(name, handerId, meta);
  }

  static #addEvent(
    element,
    name,
    callback,
    one = false,
  ) {
    const handlerId = getUniqueIdWithPrefix('event');
    DataUtil.set(element, name, handlerId);
    const meta = {
      name: name,
      callback: callback,
      one: one,
      fired: false,
    };

    this.#setEventMetaByNameAndHandlerId(name, handlerId, meta);
  }

  static #removeEvent(element, name) {
    const handlerId = DataUtil.get(element, name);
    if (!handlerId) {
      return;
    }

    const metas = this.#getEventMetasByName(name);
    if (!metas) {
      return;
    }

    metas.delete(handlerId);
    this.#setEventMetasByName(name, metas);
  }

  static trigger(element, name, event) {
    if (DataUtil.has(element, name)) {
      const handlerId = DataUtil.get(element, name);
      if (!handlerId) {
        return;
      }

      const handler = EventHandlerUtil.#getEventsMetaByHandlerId(name, handlerId);
      if (handler) {
        if (handler.name === name) {
          if (handler.one === true) {
            if (handler.fired === false) {
              EventHandlerUtil.#setFiredByNameAndHandlerId(name, handlerId, true);
              return handler.callback.call(this, event);
            }
          } else {
            return handler.callback.call(this, event);
          }
        }
      }
    }

    return null;
  }

  static on(element, name, callBack) {
    EventHandlerUtil.#addEvent(element, name, callBack, false);
  }

  static one(element, name, callBack) {
    EventHandlerUtil.#addEvent(element, name, callBack, true);
  }

  static off(element, name) {
    EventHandlerUtil.#removeEvent(element, name);
  }
}
