import { toNumber, cloneDeep } from 'lodash';
import Client from '../../core/models/Client.js';
import { clientRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    clients: new Map(),
  },
  getters: {
    getClients: (state) => () => [...state.clients.values()].map((client) => new Client(cloneDeep(client))),
    getClientById: (state) => (id) => {
      const client = state.clients.get(toNumber(id)) || { id };
      return new Client(client);
    },
  },
  mutations: {
    setClients(state, clients) {
      state.clients = new Map(clients.map((client) => [toNumber(client.id), client]));
    },
    addClient(state, data) {
      state.clients.set(toNumber(data.id), data);
    },
    deleteClientById(state, id) {
      state.clients.delete(toNumber(id));
    },
    clearStore(state) {
      state.clients.clear();
    },
  },
  actions: {
    async loadClients({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getClients(), { related: true, ...params });
      commit('setClients', data.data);
    },
    async loadClientById({ commit }, { id, params = {} }) {
      const { data } = await apiService.get(apiRoutes.getClientById(id), { related: true, ...params });
      commit('addClient', data.data);
    },
    async createClient({ dispatch }, client) {
      const { data } = await apiService.post(apiRoutes.createClient(), client);
      dispatch('loadClientById', { id: data.data.id });
    },
    async updateClientById({ dispatch }, { id, body }) {
      await apiService.patch(apiRoutes.updateClientById(id), body);
      dispatch('loadClientById', { id });
    },
    async deleteClientById({ commit }, id) {
      await apiService.delete(apiRoutes.deleteClientById(id));
      commit('deleteClientById', id);
    },
    async pauseClient({ dispatch }, id) {
      await apiService.patch(apiRoutes.pauseClient(id));
      dispatch('loadClientById', { id });
    },
    async activateClient({ dispatch }, id) {
      await apiService.patch(apiRoutes.activateClient(id));
      dispatch('loadClientById', { id });
    },
  },
});
