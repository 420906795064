import { toNumber } from 'lodash';
import FinancialDayMap from './FinancialDayMap.js';

export default class FinancialMonth {
  /**
   * @param {{
   *  performanceTargetMonth: import('../target/PerformanceTargetMonth.js').default,
   *  brandingTargetMonth: import('../target/BrandingTargetMonth.js').default,
   *  outcomeMonth: import('../outcome/OutcomeMonth.js').default,
   *  spendMonth: import('../spend/SpendMonth.js').default,
   *  monthOrderNumber: Number|String,
   *  }} data
   */
  constructor(data) {
    this.performanceTargetMonth = data.performanceTargetMonth;
    this.brandingTargetMonth = data.brandingTargetMonth;
    this.outcomeMonth = data.outcomeMonth;
    this.spendMonth = data.spendMonth;
    this.orderNumber = toNumber(data.monthOrderNumber);
    this.days = new FinancialDayMap({
      performanceTargetDays: this.performanceTargetMonth.getDays(),
      outcomeDays: this.outcomeMonth.getDays(),
      spendDays: this.spendMonth.getDays(),
    });
  }

  /**
   *
   * @returns { Number } month order number
   */
  getOrderNumber() {
    return this.orderNumber;
  }

  isOutcomeUpdated() {
    return this.outcomeMonth.isOutcomeUpdated();
  }

  getDays() {
    return this.days;
  }

  get performanceTarget() {
    return this.performanceTargetMonth;
  }

  get brandingTarget() {
    return this.brandingTargetMonth;
  }

  get outcome() {
    return this.outcomeMonth;
  }

  get spend() {
    return this.spendMonth;
  }
}
