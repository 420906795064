import dayjs from 'dayjs';
import { toNumber } from 'lodash';
import TargetYear from './PerformanceTargetYear.js';

export default class Target {
  years = new Map();

  /**
   *
   * @param {{ projectId: Number|String, target: Array }} data - target data
   */
  constructor({ projectId, target = [] }) {
    this.projectId = toNumber(projectId);
    this.years = new Map(target.map((year) => [toNumber(year.year), new TargetYear(year)]));
  }

  /**
   *
   * @returns { Number } project id
   */
  getProjectId() {
    return this.projectId;
  }

  /**
  * @param { Number|String } yearOrderNumber - year number
  * @returns { TargetYear } target year
  */
  getYear(yearOrderNumber) {
    return this.years.get(toNumber(yearOrderNumber)) || new TargetYear({ year: yearOrderNumber });
  }

  /**
   * Get the current year.
   *
   * @return { TargetYear } The current year object.
   */
  getCurrentYear() {
    const yearNumber = dayjs().get('year');
    return this.years.get(yearNumber) || new TargetYear({ year: yearNumber });
  }

  /**
   * Retrieves the previous year from the 'years' map or creates a new 'TargetYear'
   * instance if the year does not exist.
   *
   * @return { TargetYear } The previous year.
   */
  getPreviousYear() {
    const yearNumber = dayjs().subtract(1, 'year').get('year');
    return this.years.get(yearNumber) || new TargetYear({ year: yearNumber });
  }

  /**
   *
   * @returns { TargetYear[] } target years list
   */
  getYearsList() {
    return [...this.years.values()];
  }

  /**
   *
   * @returns { TargetYear[] }  complete target years list
   */
  getCompleteYears() {
    return this.getYearsList().filter((year) => year.isComplete());
  }

  getCompleteKpiYears() {
    return this.getYearsList().filter((year) => year.isCompleteKpi());
  }

  /**
   * Checks if the given `yearOrderNumber` is present in the set of years.
   *
   * @param { Number } yearOrderNumber - The year order number to check.
   * @return { Boolean } boolean - Returns `true` if the `yearOrderNumber` is present in the set of years,
   * `false` otherwise.
   */
  hasTarget(yearOrderNumber) {
    return this.years.has(toNumber(yearOrderNumber));
  }
}
