import { toNumber } from 'lodash';
import SpendYear from './SpendYear.js';

export default class Spend {
  years = new Map();

  /**
   *
   * @param {{ projectId: Number|String, spend: Array }} spendData spenddata
   */
  constructor({ projectId, spend = [] }) {
    this.projectId = toNumber(projectId);
    this.years = new Map(spend.map((year) => ([toNumber(year.year), new SpendYear(year)])));
  }

  /**
   *
   * @returns { Number } project id
   */
  getProjectId() {
    return this.projectId;
  }

  /**
   *
   * @param { Number|String } yearOrderNumber year order number, e.g. 2022
   * @returns { SpendYear } spend year of given year order number
   */
  getYear(yearOrderNumber) {
    return this.years.get(toNumber(yearOrderNumber)) || new SpendYear({ year: yearOrderNumber });
  }

  /**
   *
   * @param { Object } data year data
   * @returns { undefined }
   */
  setYear(data) {
    if (data) {
      this.years.set(toNumber(data.year), new SpendYear(data));
    }
  }

  /**
   *
   * @returns { SpendYear[] } spend years array
   */
  getYearList() {
    return [...this.years.values()];
  }
}
