import Axios from 'axios';
import VueAxios from 'vue-axios';

/**
 * @description service to call HTTP request via Axios
 */

export default class ApiService {
  static service = Axios.create({});

  /**
   * @description initialize vue axios
   */
  static init(app) {
    ApiService.service = Axios.create({
      baseURL: process.env.VUE_APP_API_SERVER_URL,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      withCredentials: true,
    });
    app.use(VueAxios, ApiService.service);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static get(resource, params = {}) {
    return ApiService.service.get(resource, { params });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param data: AxiosRequestData
   * @param configs AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static post(resource, data = {}, configs = {}) {
    return ApiService.service({
      url: resource,
      method: 'POST',
      data,
      ...configs,
    });
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param data: AxiosRequestData
   * @param configs AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static patch(resource, data = {}, configs = {}) {
    return ApiService.service({
      url: resource,
      method: 'PATCH',
      data,
      ...configs,
    });
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  static delete(resource, params = {}) {
    return ApiService.service.delete(resource, { params });
  }
}
