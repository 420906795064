import dayjs from 'dayjs';
import { isNull, isUndefined, toNumber } from 'lodash';
import { buildServerAssetUrl } from '../helpers/assets.js';

import User from './User.js';
import Team from './Team.js';
import Currency from './Currency.js';
import Vertical from './Vertical.js';

import { PROJECT_STATUSES, PROJECT_TYPES, PROJECT_GROWING_PHASES } from './constants.js';

const defaultCurrencyData = {
  name: 'euro',
  code: 'EUR',
  symbolCode: '20AC',
  symbol: '€',
};

export default class Project {
  /**
   * @typedef {{
  *  id: Number,
  *  name: String,
  *  clientId: Number,
  *  status: PROJECT_STATUSES,
  *  type: PROJECT_TYPES,
  *  email: String,
  *  iconPath: String,
  *  createdAt: String,
  *  updatedAt: String,
  *  managerId: Number,
  *  growingPhase: PROJECT_GROWING_PHASES,
  *  includeVat: Boolean,
  *  vat: Number,
  *  currencyId: Number,
  *  client: Object,
  *  clickup: Object,
  *  currency: Object,
  *  accounts: Object[],
  *  teams: Object[],
  *  manager: Object,
  *  outcome: Array,
  *  verticals: Object[],
  *   isManuallyUpdate: Boolean,
  * }} ProjectType
  */
  /**
   *
   * @param { Object } data project data
   * @param { ProjectType['id'] } data.id id
   * @param { ProjectType['name'] } data.name name
   * @param { ProjectType['clientId'] } data.clientId client id
   * @param { ProjectType['status'] } data.status status
   * @param { ProjectType['email'] } data.email email
   * @param { ProjectType['iconPath']|null } data.iconPath icon path
   * @param { ProjectType['createdAt'] } data.createdAt created date
   * @param { ProjectType['updatedAt'] } data.updatedAt updated date
   * @param { ProjectType['managerId'] } data.managerId; manager id
   * @param { ProjectType['type'] } data.type type
   * @param { ProjectType['growingPhase'] } data.growingPhase growing phase
   * @param { ProjectType['includeVat'] } data.includeVat is vat is includet
   * @param { ProjectType['vat'] } data.vat vat
   * @param { ProjectType['currencyId'] } data.currencyId currency id
   * @param { ProjectType['client'] } data.client client raw data
   * @param { ProjectType['accounts'] } data.accounts accounts data
   * @param { ProjectType['teams'] } data.teams array with team raw data
   * @param { ProjectType['manager'] } data.manager manager raw data
   * @param { ProjectType['clickup'] } data.clickup clickup data
   * @param { ProjectType['currency'] } data.currency currency raw data
   * @param { ProjectType['outcome'] } data.outcome outcome
   * @param { ProjectType['verticals'] } data.verticals project's verticals raw data
   * @param { ProjectType['isManuallyUpdate'] } data.isManuallyUpdate is project manually update
  */
  constructor(data) {
    this.id = toNumber(data.id);
    this.name = data.name;
    this.clientId = toNumber(data.clientId);
    this.status = data.status;
    this.email = data.email;
    this.iconPath = data.iconPath;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.managerId = data.managerId;
    this.type = data.type;
    this.growingPhase = data.growingPhase;
    this.includeVat = data.includeVat;
    this.vat = data.vat;
    this.currencyId = toNumber(data.currencyId);
    this.client = data.client || {};
    this.accounts = data.accounts || [];
    this.teams = data.teams || [];
    this.manager = data.manager || {};
    this.clickup = data.clickup || {};
    this.currency = data.currency || defaultCurrencyData;
    this.outcome = data.outcome || [];
    this.verticals = data.verticals || [];
    this.manuallyUpdate = data.isManuallyUpdate || false;
  }

  /**
   * Get the ID of the project.
   *
   * @return { ProjectType['id'] } The ID of the project.
   */
  getId() {
    return this.id;
  }

  /**
   * Get the name of the project.
   *
   * @return { ProjectType['name'] } The name of the project.
   */
  getName() {
    return this.name;
  }

  /**
   * Get project's client id
   *
   * @return { ProjectType['clientId'] } client id or null
   */
  getClientId() {
    return this.clientId;
  }

  /**
   * Get the status of the object.
   *
   * @return { ProjectType['status'] } The status of the object.
   */
  getStatus() {
    return this.status;
  }

  /**
   * Get type of the project
   *
   * @return { ProjectType['type'] } the type of the project.
   */
  getType() {
    return this.type;
  }

  /**
   * Retrieves the growing phase.
   *
   * @return { ProjectType['growingPhase'] } The growing phase.
   */
  getGrowingPhase() {
    return this.growingPhase;
  }

  /**
   * Get project contact email
   *
   * @returns { ProjectType['email'] } project contact email
   */
  getContactEmail() {
    return this.email;
  }

  /**
   * Get project's client
   *
   * @returns { import('./Client.js').default } project's client
   */
  getClient() {
    const Client = require('./Client.js').default;
    return new Client(this.client);
  }

  /**
   * Get project accounts
   *
   * @returns { import('./Account.js').default[] } project's accounts
   */
  getAccounts() {
    const Account = require('./Account.js').default;
    return this.accounts.map((account) => new Account(account));
  }

  /**
   * Get project currency
   *
   * @returns { Currency } project's currency
   */
  getCurrency() {
    return new Currency(this.currency);
  }

  /**
   * Get the currency ID.
   *
   * @return  { ProjectType['currencyId'] } The currency ID.
   */
  getCurrencyId() {
    return this.currencyId;
  }

  /**
   * Get project manager
   *
   * @returns { User } project's manager
  */
  getManager() {
    return new User(this.manager);
  }

  /**
   * Retrieves the manager ID.
   *
   * @return { ProjectType['managerId']} The manager ID.
   */
  getManagerId() {
    return this.managerId;
  }

  /**
   * Get teams that manage current Project
   *
   * @returns { Team[] } project's teams
   */
  getTeams() {
    return this.teams.map((team) => new Team(team));
  }

  /**
   * Get project vercitals
   *
   * @returns { Vertical[] } project's vertical
   */
  getVerticals() {
    return this.verticals.map((vertical) => new Vertical(vertical));
  }

  /**
   * Returns the icon path for the current project.
   *
   * @return { string } The icon path for the current project.
   */
  getIconPath() {
    return buildServerAssetUrl(this.iconPath);
  }

  /**
   * Returns the count of project's accounts.
   *
   * @return { number } accounts count.
   */
  getAccountsCount() {
    return this.accounts.length;
  }

  /**
   * Returns the count of teams that manage current project.
   *
   * @return { number} teams count.
   */
  getTeamsCount() {
    return this.teams.length;
  }

  /**
   * Retrieves the members of teams that manage current project.
   *
   * @return { import('./User.js').default[] } members.
   */
  getProjectTeamMembers() {
    return this.getTeams().flatMap((team) => team.getMembers());
  }

  /**
   * Retrieves the clickup automations data.
   *
   * @return { Object } The clickup automations data.
   */
  getClickupAutomations() {
    return this.clickup;
  }

  /**
   * Returns the date of creation of the project.
   *
   * @return { import('dayjs').Dayjs|null } The date of creation, or null if it does not exist.
   */
  getDateOfCreation() {
    return this.createdAt ? dayjs(this.createdAt) : null;
  }

  /**
   * Returns the date of update of the project.
   *
   * @return { import('dayjs').Dayjs|null } The date of update or null if it doesn't exist.
   */
  getDateOfUpdate() {
    return this.updatedAt ? dayjs(this.updatedAt) : null;
  }

  /**
   * Get the vat of the project.
   *
   * @return { ProjectType['vat']} vat.
   */
  getVat() {
    return this.vat;
  }

  /**
   * Check if project include vat.
   *
   * @return {boolean} Return true if project includes vat, false otherwise.
   */
  isIncludeVat() {
    return this.includeVat;
  }

  /**
   * Checks if the project is an ecommerce project.
   *
   * @return { boolean } Returns true if the project type is ECOMMERCE, false otherwise.
   */
  isEcommerceProject() {
    return this.type === PROJECT_TYPES.ECOMMERCE;
  }

  /**
   * Checks if the project is a lead generation project.
   *
   * @return { boolean } Returns true if the project type is lead generation, otherwise false.
   */
  isLeadGenerationProject() {
    return this.type === PROJECT_TYPES.LEAD_GENERATION;
  }

  /**
   * Checks if the project is in the growing phase.
   *
   * @return { boolean } Returns true if the project is in the growing phase, false otherwise.
   */
  isProjectGrowing() {
    return this.growingPhase === PROJECT_GROWING_PHASES.GROWING;
  }

  /**
   * Checks if the project is matured.
   *
   * @return { boolean } Returns true if the project is matured, false otherwise.
   */
  isProjectMatured() {
    return this.growingPhase === PROJECT_GROWING_PHASES.MATURE;
  }

  /**
   * Determines if the project is in the onboarding phase.
   *
   * @return { boolean } Returns true if the project is in the onboarding phase, false otherwise.
   */
  isProjectOnBoarding() {
    return this.growingPhase === PROJECT_GROWING_PHASES.BOARDING;
  }

  /**
   * Get project contact email
   *
   * @returns { Boolean } email
   */
  hasContactEmail() {
    return !isNull(this.email) && !isUndefined(this.email);
  }

  /**
   * Checks if the project is active.
   *
   * @return { boolean } Returns true if the project is active, otherwise false.
   */
  isActive() {
    return this.status === PROJECT_STATUSES.ACTIVE;
  }

  /**
   * Determines if the project is currently paused.
   *
   * @return { boolean } Returns true if the project is paused, false otherwise.
   */
  isPaused() {
    return this.status === PROJECT_STATUSES.PAUSED;
  }

  /**
   * Checks if the project is deleted.
   *
   * @return { boolean } Returns true if the project is deleted, false otherwise.
   */
  isDeleted() {
    return this.status === PROJECT_STATUSES.DELETED;
  }

  /**
   * Check if project is manually update by manager.
   *
   * @return { boolean } Returns true if update, otherwise false.
   */
  isManuallyUpdate() {
    return this.manuallyUpdate;
  }

  /**
   * Checks if a manager is assigned.
   *
   * @return { boolean } Returns true if a manager is assigned, otherwise false.
   */
  isManagerAssigned() {
    return !!this.managerId;
  }
}
