// @ts-nocheck
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store/index.js';

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('../layout/BaseLayout.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/BaseDashboard.vue'),
      },
      {
        path: '/users',
        name: 'users',
        component: () => import('../views/users/UsersList.vue'),
      },
      {
        path: '/users/:userId',
        name: 'user',
        component: () => import('../views/users/UserCard.vue'),
        props: true,
        children: [
          {
            path: 'overview',
            name: 'userOverview',
            component: () => import('../views/users/UserOverview.vue'),
            props: true,
          },
          {
            path: 'settings',
            name: 'userSettings',
            component: () => import('../views/users/UserSettings.vue'),
            props: true,
          },
          {
            path: 'projects',
            name: 'userProjects',
            component: () => import('../views/users/UserProjects.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/users/newUser',
        name: 'UserNew',
        component: () => import('../views/users/UserNew.vue'),
      },
      {
        path: '/clients',
        name: 'clients',
        component: () => import('../views/clients/ClientsList.vue'),
      },
      {
        path: '/clients/overview/:id',
        name: 'clientOverview',
        component: () => import('../views/clients/ClientOverview.vue'),
        props: true,
      },
      {
        path: '/teams',
        name: 'teams',
        component: () => import('../views/teams/TeamsList.vue'),
      },
      {
        path: '/teams/:id/edit',
        name: 'TeamEdit',
        component: () => import('../views/teams/TeamEdit.vue'),
        props: true,
      },
      {
        path: '/teams/new',
        name: 'TeamNew',
        component: () => import('../views/teams/TeamNew.vue'),
      },
      {
        path: '/teams/overview/:id',
        name: 'TeamOverview',
        component: () => import('../views/teams/TeamOverview.vue'),
        props: true,
      },
      {
        path: '/departments',
        name: 'departments',
        component: () => import('../views/departments/DepartmentsList.vue'),
      },
      {
        path: '/departments/overview/:id',
        name: 'departmentOverview',
        component: () => import('../views/departments/DepartmentOverview.vue'),
        props: true,
      },
      {
        path: '/departments/edit/:id',
        name: 'departmentEdit',
        component: () => import('../views/departments/DepartmentsEdit.vue'),
        props: true,
      },
      {
        path: '/positions',
        name: 'positions',
        component: () => import('../views/positions/PositionsList.vue'),
      },
      {
        path: '/positions/overview/:id',
        name: 'positionOverview',
        component: () => import('../views/positions/PositionOverview.vue'),
        props: true,
      },
      {
        path: '/positions/edit/:id',
        name: 'positionEdit',
        component: () => import('../views/positions/PositionEdit.vue'),
        props: true,
      },
      {
        path: '/projects',
        name: 'Projects',
        component: () => import('../views/projects/ProjectsList.vue'),
      },
      {
        path: '/projects/new',
        name: 'ProjectNew',
        component: () => import('../views/projects/AppProjectNew.vue'),
      },
      {
        path: '/projects/:id',
        component: () => import('../views/projects/AppProjectCard.vue'),
        name: 'ProjectCard',
        props: true,
        children: [
          {
            path: 'overview',
            name: 'ProjectOverview',
            component: () => import('../views/projects/AppProjectOverview.vue'),
            props: true,
          },
          {
            path: 'edit',
            name: 'ProjectEdit',
            component: () => import('../views/projects/AppProjectEdit.vue'),
            props: true,
          },
          // {
          //   path: 'outcome',
          //   name: 'ProjectOutcome',
          //   component: () => import('../views/projects/AppProjectOutcome.vue'),
          //   props: true,
          // },
          {
            path: 'outcome/updates',
            name: 'ProjectOutcomeUpdates',
            component: () => import('../views/projects/AppOutcomeUpdates.vue'),
            props: true,
          },
          {
            path: 'target/branded',
            name: 'ProjectBrandedTarget',
            component: () => import('../views/projects/branding_target/AppProjectBrandingTarget.vue'),
            props: true,
          },
          {
            path: 'target/performance',
            name: 'ProjectPerformanceTarget',
            component: () => import('../views/projects/performance_target/AppProjectPerformanceTarget.vue'),
            props: true,
          },
          {
            path: 'spend',
            name: 'ProjectSpend',
            component: () => import('../views/projects/AppProjectSpend.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/accounts/list',
        name: 'accounts',
        component: () => import('../views/accounts/AccountsList.vue'),
      },
      {
        path: '/account/:id',
        name: 'Account',
        component: () => import('../views/accounts/AppAccountCard.vue'),
        props: true,
        children: [
          {
            path: 'overview',
            name: 'AccountOverview',
            component: () => import('../views/accounts/AppAccountOverview.vue'),
            props: true,
          },
          {
            path: 'settings',
            name: 'AccountSettings',
            component: () => import('../views/accounts/AppAccountEdit.vue'),
            props: true,
          },
          {
            path: 'airbyte',
            name: 'AccountAirbyte',
            component: () => import('../views/accounts/AppAccountAirbyteSources.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/accounts/airbyte',
        name: 'Airbyte',
        component: () => import('../views/airbyte/AppAirbyte.vue'),
      },
      {
        path: '/accounts/new',
        name: 'AccountNew',
        component: () => import('../views/accounts/AppAccountNew.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('../views/authentication/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('../views/authentication/basic-flow/SignIn.vue'),
        props: true,
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('../views/authentication/Error404.vue'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import('../views/authentication/Error500.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit('resetLayoutConfig');
  store.dispatch('verifyAuth');
  // const isAuthenticated = () => store.getters.isUserAuthenticated;
  // if (!isAuthenticated() && to.name !== 'sign-in') {
  //   return { name: 'sign-in' };
  // }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
