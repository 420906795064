import { createI18n } from 'vue-i18n';
import users from '../locales/en/users.js';
import accounts from '../locales/en/components/accounts.js';
import accountsView from '../locales/en/views/accounts.js';
import searchBar from '../locales/en/components/searchBar.js';
import airbyte from '../locales/en/components/airbyte.js';

const messages = {
  en: {
    signIn: {
      header: 'Sign In to Omnicliq',
      form: {
        email: 'Email',
        password: 'Password',
        buttons: {
          submit: {
            name: 'Continue',
            indicator: 'Please wait...',
          },
        },
      },
      alternativeAuth: 'or',
      resetPassword: 'Forgot Password?',
    },
    components: {
      searchBar,
      applications: {
        airbyte,
        projects: {
          actions: {
            alert: {
              success: 'The project has been {action}.',
              unsuccess: 'Something went wrong. Try again.',
            },
            activate: {
              action: 'activate',
              result: 'activated',
            },
            pause: {
              action: 'pause',
              result: 'paused',
            },
            delete: {
              action: 'delete',
              result: 'deleted',
            },
            edit: {
              action: 'edit',
              result: 'updated',
            },
            create: {
              action: 'create',
              result: 'created',
            },
            indicator: 'Please wait...',
          },
          list: {
            headers: {
              project: 'Project',
              manager: 'Manager',
              accounts: 'Accounts Count',
              status: 'Status',
              actions: 'Actions',
            },
            delete: {
              confirmation: 'Are you sure to delete this project?',
              notification: 'Project have been deleted successfully!',
            },
            actions: {
              stauts: '{action} project',
              edit: '{action} project',
              delete: '{action} project',
            },
          },
          overview: {
            header: 'Project Details',
            actions: {
              name: 'Actions',
              buttons: {
                edit: 'Edit',
                status: '{status}',
                delete: 'Delete',
              },
            },
            name: 'Name',
            status: 'Status',
            manager: 'Manager',
            client: 'Client',
            teams: 'Teams',
            accounts: 'Accounts',
          },
          new: {
            header: 'New Project',
            successConfirmation: 'Project has been created successfully!',
          },
          edit: {
            header: 'Edit Project',
            successConfirmation: 'Project has been updated successfully!',
          },
          error: {
            internalError: 'Something went wrong, try again!',
          },
          card: {
            accounts: 'accounts',
            manager: 'Manager',
            notAssignedManager: 'manager is not assigned',
          },
          form: {
            name: {
              label: 'Name',
              placeholder: 'e.g. ILLY COFFEE',
            },
            type: {
              label: 'Type',
              placeholder: 'Select project type',
            },
            manager: {
              label: 'Manager',
              title: 'Select a user who manage this project',
              placeholder: 'Select manager',
              tooltip: 'Please first select a team.',
            },
            client: {
              label: 'Client',
              title: 'Select a relevant project client',
              placeholder: 'Select client',
            },
            teams: {
              label: 'Teams',
              placeholder: 'Select teams',
            },
            status: {
              label: 'Status',
              placeholder: 'Select status',
            },
            clickup: {
              label: 'ClickUp Automations',
              placeholder: 'Select clickup automations',
            },
            icon: {
              label: 'Icon',
            },
            buttons: {
              reset: 'Discard',
              submit: 'Submit',
            },
          },
        },
        accounts,
      },
    },
    views: {
      positions: {
        list: {
          header: 'Positions',
          newPositionButton: 'New Position',
          headers: {
            position: 'Position',
            department: 'Department',
            usersCount: 'Users Count',
            actions: 'Actions',
          },
        },
        new: {
          header: 'New Position',
        },
        edit: {
          header: 'Edit Position',
        },
        form: {
          name: {
            label: 'Name',
            title: 'Specify a position name',
            placeholder: 'e.g. Manager',
          },
          department: {
            label: 'Department',
            placeholder: 'Select a department',
          },
          users: {
            label: 'Users',
            title: 'Select users that needs assign position',
            placeholder: 'Select users',
          },
          buttons: {
            reset: {
              text: 'Discard',
            },
            submit: {
              text: 'Submit',
              indicator: 'Pleace wait...',
            },
          },
        },
        card: {
          ditailsHeader: 'Position Details',
          editButton: 'Edit Position',
          name: 'Name',
          department: 'Department',
          users: 'Users',
        },
      },
      roles: {
        list: {
          header: 'Roles',
          newRoleButton: 'Add New Role',
        },
        card: {
          buttons: {
            view: 'View Role',
            edit: 'Edit Role',
          },
        },
        new: {
          header: 'Add New Role',
        },
        edit: {
          header: 'Edit Role',
        },
        form: {
          name: {
            label: 'Role Name',
            title: 'Specify a role name',
          },
          users: {
            label: 'Users',
            title: 'Select users that needs assign role',
          },
          buttons: {
            reset: {
              name: 'Discard',
            },
            submit: {
              name: 'Submit',
              indicator: 'Pleace wait...',
            },
          },
        },
      },
      users,
      phones: {
        list: {
          header: 'Phones',
          tableHeaders: {
            phones: 'Phone',
            user: 'Owner',
            actions: 'Actions',
          },
          createButton: {
            title: 'Click to add new phone',
            name: 'New Phone',
          },
        },
        overview: {
          header: 'Phone details',
          number: 'Full Number',
          owner: 'Owner',
          editButton: 'Edit Phone',
        },
      },
      teams: {
        list: {
          header: 'Teams',
          tableHeaders: {
            team: 'Team',
            supervisor: 'Supervisor',
            actions: 'Actions',
          },
          createButton: {
            title: 'Click to add a team',
            name: 'New Team',
          },
        },
        overview: {
          header: 'Team details',
          name: 'Name',
          manager: 'Managers',
          members: 'Members',
          projects: 'Projects',
          editButton: 'Edit Team',
        },
        new: {
          header: 'Create Team',
          notice: 'Team has been created successfully!',
        },
        edit: {
          header: 'Edit Team',
          notice: 'Team has been updated successfully!',
        },
        form: {
          name: 'Name',
          department: 'Department',
          supervisor: 'Supervisor',
          managers: 'Managers',
          members: 'Members',
          projects: 'Projects',
          buttons: {
            discard: 'Discard',
            submit: 'Submit',
          },
        },
      },
      departments: {
        list: {
          header: 'Departments',
          newDepartmentButton: {
            name: 'New Department',
            title: 'Click to add new department',
          },
          listHeaders: {
            department: 'Department',
            members: 'Members Count',
            actions: 'Actions',
          },
          actions: {
            dropdownButton: 'Actions',
            edit: 'Edit',
            delete: 'Delete',
          },
        },
        overview: {
          header: 'Department details',
          name: 'Name',
          editButton: 'Edit Department',
          managers: 'Managers',
          members: 'Members',
        },
        new: {
          header: 'New Department',
        },
        edit: {
          header: 'Edit Department',
          alert: {
            confirmation: 'Department has been updated successfully!',
          },
        },
        delete: {
          alert: {
            confirmation: 'Are you sure to delete this department?',
            notification: 'Department has been deleted successfully!',
          },
        },
        form: {
          name: {
            label: 'Name',
            title: 'Specify department name',
          },
          managers: {
            label: 'Managers',
            title: 'Select managers',
          },
          members: {
            label: 'Members',
            title: 'Select members',
          },
          teams: {
            label: 'Teams',
            title: 'Select teams',
          },
        },
      },
      clients: {
        list: {
          header: 'Clients',
          newClientButton: {
            name: 'New Client',
            title: 'Click to add new client',
          },
          listHeaders: {
            clients: 'Clients',
            projects: 'Projects Count',
            users: 'Users Count',
            actions: 'Actions',
          },
          actions: {
            dropdownButton: 'Actions',
            edit: 'Edit',
            delete: 'Delete',
          },
        },
        overview: {
          header: 'Clients details',
          name: 'Name',
          editButton: 'Edit Client',
          projects: 'Projects',
          accounts: 'Accounts',
        },
        new: {
          header: 'New Client',
        },
        edit: {
          header: 'Edit Client',
          alert: {
            confirmation: 'Client has been updated successfully!',
          },
        },
        delete: {
          alert: {
            confirmation: 'Are you sure to delete this client?',
            notification: 'Client has been deleted successfully!',
          },
        },
        form: {
          name: {
            label: 'Name',
            title: 'Specify client name',
          },
        },
      },
      projects: {
        list: {
          createButton: 'New Project',
        },
      },
      accounts: accountsView,
    },
    administration: 'Administration',
    applications: 'Applications',
    dashboards: 'Dashboards',
    welcome: 'Welcome',
    logs: 'Logs',
    roles: 'Roles',
    omnicliq: 'Omnicliq',
    positions: 'Positions',
    users: 'Users',
    departments: 'Departments',
    phones: 'Phones',
    dashboard: 'Dashboard',
    calendar: 'Calendar',
    layoutBuilder: 'Layout builder',
    craft: 'Crafted',
    pages: 'Pages',
    profile: 'Profile',
    profileOverview: 'Overview',
    campaigns: 'Campaigns',
    documents: 'Documents',
    connections: 'Connections',
    wizards: 'Wizards',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    account: 'Account',
    teams: 'Teams',
    clients: 'Clients',
    accountOverview: 'Overview',
    settings: 'Settings',
    authentication: 'Authentication',
    basicFlow: 'Basic Flow',
    passwordReset: 'Password Reset',
    error404: 'Error 404',
    error500: 'Error 500',
    apps: 'Apps',
    chat: 'Chat',
    privateChat: 'Private Chat',
    groupChat: 'Group Chat',
    drawerChat: 'Drawer Chat',
    widgets: 'Widgets',
    widgetsLists: 'Lists',
    widgetsStatistics: 'Statistics',
    widgetsCharts: 'Charts',
    widgetsMixed: 'Mixed',
    widgetsTables: 'Tables',
    widgetsFeeds: 'Feeds',
    changelog: 'Changelog',
    docsAndComponents: 'Docs & Components',
    megaMenu: 'Mega Menu',
    exampleLink: 'Example link',
    modals: 'Modals',
    general: 'General',
    inviteFriends: 'Invite Friends',
    viewUsers: 'View Users',
    upgradePlan: 'Upgrade Plan',
    shareAndEarn: 'Share & Earn',
    forms: 'Forms',
    newTarget: 'New Target',
    newCard: 'New Card',
    newAddress: 'New Address',
    createAPIKey: 'Create API Key',
    twoFactorAuth: 'Two Factor Auth',
    createApp: 'Create App',
    createAccount: 'Create Account',
    documentation: 'Documentation',
    resources: 'Resources',
    activity: 'Activity',
    customers: 'Customers',
    gettingStarted: 'Getting Started',
    customersListing: 'Customers Listing',
    customerDetails: 'Customers Details',
    calendarApp: 'Calendar',
    subscriptions: 'Subscriptions',
    getStarted: 'Getting Started',
    subscriptionList: 'Subscription List',
    addSubscription: 'Add Subscription',
    viewSubscription: 'View Subscription',
    userManagement: 'User Management',
    clientManagement: 'Client Management',
    fileManagement: 'File Manager',
    invoiceManagement: 'Invoice Manager',
    currentUserProjects: 'My Projects',
    currentUserAccounts: 'My Projects Accounts',
    projects: 'Projects',
    accounts: 'Accounts List',
    reports: 'Reports',
    files: 'Useful Files',
    invoices: 'Invoices',
    accountsList: 'Accounts List',
    projectsList: 'Projects List',
    airbyteList: 'Airbyte connections',
  },
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  messages,
});

export default i18n;
