export default {
  list: {
    header: 'Users',
    newUserButton: {
      text: 'New User',
      title: 'Click to add new user',
    },
    tableHeaders: {
      user: 'User',
      position: 'Position',
      loginDate: 'Last login',
      status: 'Status',
      hiringDate: 'Hiring date',
      actions: 'Actions',
      primaryPhone: 'Primary phone',
      role: 'Role',
    },
    actions: {
      dropdownButton: 'Actions',
      edit: 'Edit',
      delete: 'Delete',
    },
  },
  card: {
    projects: 'Projects',
    overview: 'Overview',
    settings: 'Settings',
    logs: 'Logs',
  },
  overview: {
    header: 'Profile Details',
    editButton: 'Edit Prifile',
    fullName: 'Full Name',
    email: 'Email',
    primaryPhone: 'Primary Phone',
    extraPhone: 'Extra Phone',
    position: 'Position',
    teams: 'Teams',
    teamSupervisor: 'Team Supervisor',
    department: 'Department',
    hiringDate: 'Hiring Date',
  },
  new: {
    header: 'Add New User',
  },
  update: {
    headers: {
      profile: 'Profile Details',
      signin: 'Sign-in Method',
    },
  },
  form: {
    steps: {
      personalInformation: {
        header: 'Personal information',
        description: 'Enter user personal information',
      },
      contactInfo: {
        header: 'Contact information',
        description: 'Enter user contact information',
      },
      businessInformation: {
        header: 'Business information',
        description: 'Enter user company information',
      },
      accountInformation: {
        header: 'Account information',
        description: 'Enter user account information',
      },
    },
    firstName: {
      label: 'First Name',
      placeholder: 'e.g. Dimitris',
    },
    lastName: {
      label: 'Last Name',
      placeholder: 'e.g. Karamanlis',
    },
    email: {
      label: 'Email',
      placeholder: 'e.g. dkaramanlis{\'@\'}omnicliq.com',
    },
    password: {
      label: 'Password',
      placeholder: 'e.g. ga4562-$13',
      generatePasswordButton: 'Generate',
    },
    phone: {
      label: '{name} phone',
      placeholder: 'e.g. 6996969999',
    },
    clickupId: {
      label: 'ClickUp',
      placeholder: 'Select a clickup account',
    },
    messengerId: {
      label: 'Messenger ID',
      placeholder: 'e.g. 123456789123456798',
    },
    role: {
      label: 'Role',
      placeholder: 'Select a role',
    },
    type: {
      label: 'Type',
      placeholder: 'Select user type',
    },
    status: {
      label: 'Status',
      placeholder: 'Select user status',
    },
    department: {
      label: 'Department',
      placeholder: 'Select a department',
    },
    position: {
      label: 'Position',
      placeholder: 'Select user position',
      tooltip: 'Please first select a department',
    },
    teams: {
      label: 'Teams',
      placeholder: 'Select a team',
      tooltip: 'Please first select a department',
    },
    hiringDate: {
      label: 'Hiring date',
      placeholder: 'Pick a date',
    },
    buttons: {
      create: {},
      edit: {
        discard: 'Discard',
        save: 'Save Changes',
      },
    },
  },
  signInForms: {
    passwordPlaceholder: 'e.g. ga4562-$13',
    buttons: {
      collapse: {
        text: 'Change {text}',
      },
      cancel: {
        text: 'Cancel',
      },
      submit: {
        text: 'Update {text}',
      },
      password: {
        text: 'Generate Password',
      },
    },
    emailForm: {
      header: 'Email Adress',
      email: {
        label: 'Enter New Email Address',
        placeholder: 'e.g. dkaramanlis{\'@\'}omnicliq.com',
      },
      password: {
        label: 'Confirm Password',
      },
    },
    passwordForm: {
      header: 'Password',
      currentPassword: {
        label: 'Current Password',
      },
      newPassword: {
        label: 'New Password',
      },
      confirmPassword: {
        label: 'Confirm New Password',
      },
      requirement: 'Password must be at least {min} character and contain symbols',
    },
  },
};
