import { toNumber } from 'lodash';

export default class Currency {
  /**
   *
   * @param { Object } data team data
   * @param { Number } data.id id
   * @param { String } data.name name
   * @param { String } data.code currency code e.g. EUR
   * @param { String } data.symbolCode currency sumbyl code e.g. €
   * @param { String } data.symbol currency symbol e.g. 20AC
   */
  // @ts-ignore
  constructor(data = {}) {
    this.id = toNumber(data.id);
    this.name = data.name;
    this.code = data.code;
    this.symbolCode = data.symbolCode;
    this.symbol = data.symbol;
  }

  /**
   * Get the ID of the currency.
   *
   * @return { number } The ID of the currency.
   */
  getId() {
    return this.id;
  }

  /**
   * Get the name of the currency.
   *
   * @return { string } The name of the currency.
   */
  getName() {
    return this.name;
  }

  /**
   * Get the code of the currency(e.g. EUR, USD).
   *
   * @return { string } The code of the currency.
   */
  getCode() {
    return this.code;
  }

  /**
   * Get the symbol of the currency(e.g. €, $).
   *
   * @return { string } The symbol of the currency.
   */
  getSymbol() {
    return this.symbol;
  }

  /**
   * Get the symbol html code(e.g. 0024).
   *
   * @return { string } The symbol code.
   */
  getSymbolCode() {
    return this.symbolCode;
  }
}
