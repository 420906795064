import { cloneDeep, toNumber } from 'lodash';
import Account from '../../core/models/Account.js';
import ChannelSpend from '../../core/models/spend/ChannelSpend.js';
import { accountRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    accounts: new Map(),
    spend: new Map(),
  },
  getters: {
    getAccounts: (state) => () => [...state.accounts.values()].map((account) => new Account(cloneDeep(account))),
    getAccountById: (state) => (uniqId) => {
      const account = state.accounts.get(toNumber(uniqId)) || { uniqId };
      return new Account(cloneDeep(account));
    },
    /**
     * Returns a ChannelSpend object for a given account ID and channel ID.
     *
     * @return { function({ accountId: Number|String }): ChannelSpend } The ChannelSpend object.
     */
    getSpendByAccountId: (state) => (accountId) => {
      const spend = state.spend.get(toNumber(accountId)) || [];
      return new ChannelSpend({ accountId, spend });
    },
  },
  mutations: {
    setAccounts(state, accounts = []) {
      state.accounts = new Map(accounts.map((account) => ([account.uniqId, account])));
    },
    addAccount(state, account) {
      state.accounts.set(account.uniqId, account);
    },
    deleteAccount(state, accountId) {
      state.accounts.delete(toNumber(accountId));
    },
    clearStore(state) {
      state.accounts.clear();
      state.spend.clear();
    },
    addSpend(state, { spend, accountId }) {
      state.spend.set(toNumber(accountId), spend);
    },
  },
  actions: {
    async loadAccounts({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getAccounts(), { related: true, ...params });
      commit('setAccounts', data.data);
    },
    async loadAccountById({ commit }, { id, params }) {
      const { data } = await apiService.get(apiRoutes.getAccountById(id), { related: true, ...params });
      commit('addAccount', data.data);
    },
    async createAccount({ dispatch }, body) {
      const { data } = await apiService.post(apiRoutes.createAccount(), body);
      await dispatch('loadAccountById', { id: data.data.uniqId });
      return data.data;
    },
    async updateAccount({ dispatch }, { id, body }) {
      await apiService.patch(apiRoutes.updateAccount(id), body);
      dispatch('loadAccountById', { id });
    },
    async deleteAccount({ commit }, id) {
      await apiService.delete(apiRoutes.deleteAccount(id));
      commit('deleteAccount', id);
    },
    async loadAccountSpendById({ commit }, { id, params = {} }) {
      const { data } = await apiService.get(apiRoutes.getAccountSpend(id), params);
      commit('addSpend', { spend: data.data, accountId: id });
    },
  },
});
