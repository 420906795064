import ApiService from '../../core/services/ApiService.js';
import User from '../../core/models/User.js';
import permissionsConfig from '../../core/config/permissionsConfig.js';
import getPermissions from '../../core/helpers/permissions.js';

export default {
  state: {
    user: {},
    error: {},
    isAuthenticated: false,
  },
  getters: {
    getCurrentUser: (state) => () => new User(state.user),
    isUserAuthenticated: (state) => () => state.isAuthenticated,
    getAuthError: (state) => () => state.error,
    hasAuthError: (state) => () => Object.keys(state.error).length > 0,
  },
  mutations: {
    setError(state, error) {
      state.error = error || {};
    },
    setAuth(state, { isAuthenticated, user }) {
      state.isAuthenticated = isAuthenticated;
      state.user = user || {};
      state.error = {};

      let permissions;
      if (user.status === 'inactive') {
        permissions = getPermissions({}, {});
      } else {
        permissions = getPermissions(user.role || {}, user.permissions || {});
      }

      permissionsConfig.update(permissions);
    },
    setUser(state, user) {
      state.user = user;
    },
    logOut(state) {
      state.isAuthenticated = false;
      state.user = {};
      state.error = {};
    },
  },
  actions: {
    async logIn({ commit }, credentials) {
      await ApiService.post('/login', credentials)
        .then(({ data }) => commit('setAuth', data))
        .catch(({ response }) => commit('setError', response));
    },
    async logOut({ commit }) {
      await ApiService.get('/logout')
        .then(() => commit('logOut'))
        .catch(({ response }) => commit('setError', response));
    },
    async verifyAuth({ commit, state }) {
      if (!state.isAuthenticated) {
        await ApiService.get('/verifyAuth')
          .then(({ data }) => commit('setAuth', data))
          .catch(({ response }) => {
            commit('setError', response);
            commit('logOut');
          });
      }
    },
  },
};
