export default (apiService) => ({
  namespaced: true,
  state: {
    automations: [],
    users: [],
  },
  getters: {
    getAutomations: (state) => () => [...state.automations],
    getUsers: (state) => () => [...state.users],
  },
  mutations: {
    setAutomations(state, automations) {
      state.automations = automations;
    },
    setUsers(state, users) {
      state.users = users;
    },
    clearStore(state) {
      state.automations = [];
      state.users = [];
    },
  },
  actions: {
    async loadAutomations({ commit }) {
      const { data } = await apiService.get('/clickup/automations');
      const automations = data.data;
      commit('setAutomations', automations);
      return automations;
    },
    async loadUsers({ commit }) {
      const { data } = await apiService.get('/clickup/users');
      const users = data.data;
      commit('setUsers', users);
      return users;
    },
  },
});
