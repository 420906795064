const colorMap = {
  log: '#a8a8a8',
  info: '#52ba6e',
  debug: '#be58e0',
  error: '#ff3333',
};

export default class Logger {
  #name;

  /**
   *
   * @param { Object } config
   * @param { String } config.name - Logger space name.
   * @param { Boolean= } config.enabled - Is logger enabled.
   */
  constructor({ name, enabled = true }) {
    this.#name = name;
    Logger.#nameSpaces[this.#name] = enabled;
  }

  static #nameSpaces = {};

  static #modes = {
    LOG: 'log',
    INFO: 'info',
    DEBUG: 'debug',
    ERROR: 'error',
    WARN: 'warn',
  };

  static #config = {
    enabled: true,
    log: true,
    info: true,
    debug: true,
    error: true,
    warn: true,
  };

  static #log(mode, prefix = '', ...value) {
    // eslint-disable-next-line no-console
    const output = value instanceof Array ? value : [value];
    console.log(`%c[${mode.toUpperCase()}]${prefix.length > 0
      ? ':'
      : ''}${prefix} - `, `color: ${colorMap[mode] || ''}`, ...output);
  }

  /**
   * @param { Object } config
   * @param { Boolean= } config.enabled - Is logger enabled
   * @param { Boolean= } config.log - Is log mode enabled
   * @param { Boolean= } config.info - Is info mode enabled
   * @param { Boolean= } config.debug - Is debug mode enabled
   * @param { Boolean= } config.error - Is error mode enabled
   * @param { Boolean= } config.warn - Is warn mode enabled
   */
  static setConfig(config) {
    this.#config = { ...this.#config, ...config };
  }

  /**
   * Checks if the given namespace is enabled.
   *
   * @param { string } name - The name of the namespace.
   * @return {boolean} Returns true if the namespace is enabled, false otherwise.
   */
  static isEnabledNamespace(name) {
    return this.#nameSpaces[name] || false;
  }

  /**
   * Check if the specified mode is enabled.
   *
   * @param { string } mode - The name of mode to check
   * @return { boolean } Returns true if the mode is enabled, false otherwise.
   */
  static isEnabledMode(mode) {
    return this.#config[mode];
  }

  /**
   * Disables a namespace.
   *
   * @param { string } name - The name of the namespace to disable.
   */
  static desableNamespace(name) {
    this.#nameSpaces[name] = false;
  }

  /**
   * Enables a namespace.
   *
   * @param { string } name - The name of the namespace to enable.
   */
  static enableNamespace(name) {
    this.#nameSpaces[name] = true;
  }

  /**
   * Enables a specific mode.
   *
   * @param { string } mode - The mode to enable.
   */
  static enbaleMode(mode) {
    this.#config[mode] = true;
  }

  /**
   * Disables a specific mode.
   *
   * @param { string } mode - The mode to disable
   */
  static desableMode(mode) {
    this.#config[mode] = false;
  }

  /**
   * Enable logger.
   */
  static enable() {
    this.#config.enabled = true;
  }

  /**
   * Disable logger.
   */
  static desable() {
    this.#config.enabled = false;
  }

  /**
   * Logs the given values if the mode is enabled and the namespace is enabled.
   *
   * @param {...*} value - The values to be logged.
   */
  log(...value) {
    const mode = Logger.#modes.LOG;
    if (Logger.isEnabledMode(mode) && Logger.isEnabledNamespace(this.#name)) {
      Logger.#log(mode, this.#name, ...value);
    }
  }

  /**
   * Logs a debug message if the debug mode is enabled and the namespace is enabled.
   *
   * @param {...*
   * } value - The values to be logged.
   */
  debug(...value) {
    const mode = Logger.#modes.DEBUG;
    if (Logger.isEnabledMode(mode) && Logger.isEnabledNamespace(this.#name)) {
      Logger.#log(mode, this.#name, ...value);
    }
  }

  /**
   * Logs information if the mode is enabled and the namespace is enabled.
   *
   * @param {...*} value - The values to be logged.
   */
  info(...value) {
    const mode = Logger.#modes.INFO;
    if (Logger.isEnabledMode(mode) && Logger.isEnabledNamespace(this.#name)) {
      Logger.#log(mode, this.#name, ...value);
    }
  }

  /**
   * Logs an error message if the error mode is enabled and the namespace is enabled.
   *
   * @param {...*} value - The error message to be logged.
   */
  error(...value) {
    const mode = Logger.#modes.ERROR;
    if (Logger.isEnabledMode(mode) && Logger.isEnabledNamespace(this.#name)) {
      Logger.#log(mode, this.#name, ...value);
    }
  }

  /**
   * Logs a warning message if the mode is enabled and the namespace is enabled.
   *
   * @param {...*} value - The value to be logged as a warning.
   */
  warn(...value) {
    const mode = Logger.#modes.WARN;
    if (Logger.isEnabledMode(mode) && Logger.isEnabledNamespace(this.#name)) {
      Logger.#log(mode, this.#name, ...value);
    }
  }

  getName() {
    return this.#name;
  }

  /**
   *
   * @param {{
   *  name: String
   * }} config - extend name
   * @returns { Logger }
   */
  extend(config) {
    if (!config.name) {
      throw new Error('Namespace name is required');
    }
    const newLogName = `${this.#name}:${config.name}`;
    return new Logger({ ...config, name: newLogName });
  }
}
