import { join } from 'path-browserify';
import { toString } from 'lodash';

const baseRoutes = {
  projects: '/projects',
  clients: '/clients',
  accounts: '/accounts',
  users: '/users',
  outcome: '/outcome',
  spend: '/spend',
  target: '/target',
  verticals: '/verticals',
  teams: '/teams',
  departments: '/departments',
  positions: '/positions',
  channels: '/channels',
  currencies: '/currencies',
  airbyte: '/airbyte',
};

export const projectRoutes = {
  getProjects: () => baseRoutes.projects,
  getProjectById: (id) => join(baseRoutes.projects, toString(id)),
  createProject: () => baseRoutes.projects,
  updateProject: (id) => join(baseRoutes.projects, toString(id)),
  deleteProject: (id) => join(baseRoutes.projects, toString(id)),
  updateOutcome: (id) => join(baseRoutes.projects, toString(id), 'outcome', 'updates'),
  activateProject: (id) => join(baseRoutes.projects, toString(id), 'activate'),
  pauseProject: (id) => join(baseRoutes.projects, toString(id), 'pause'),
  getSpend: (id) => join(baseRoutes.projects, toString(id), 'spend'),
  getOutcome: (id) => join(baseRoutes.projects, toString(id), 'outcome'),
  getPerformanceTarget: (id) => join(baseRoutes.projects, toString(id), 'target', 'performance'),
  getBrandingTarget: (id) => join(baseRoutes.projects, toString(id), 'target', 'branding'),
  createPerformanceTarget: (id) => join(baseRoutes.projects, toString(id), 'target', 'performance'),
  createBrandingTarget: (id) => join(baseRoutes.projects, toString(id), 'target', 'branding'),
  updatePerformanceTarget: (id) => join(baseRoutes.projects, toString(id), 'target', 'performance'),
  deletePerformanceTarget: (id, year) => `${
    join(baseRoutes.projects, toString(id), 'target', 'performance')}?year=${year}`,
  updateBrandingTarget: (id) => join(baseRoutes.projects, toString(id), 'target', 'branding'),
};

export const accountRoutes = {
  getAccounts: () => baseRoutes.accounts,
  getAccountById: (id) => join(baseRoutes.accounts, toString(id)),
  createAccount: () => baseRoutes.accounts,
  updateAccount: (id) => join(baseRoutes.accounts, toString(id)),
  deleteAccount: (id) => join(baseRoutes.accounts, toString(id)),
  getAccountSpend: (id) => join(baseRoutes.accounts, toString(id), 'spend'),
  pauseAccount: (id) => join(baseRoutes.accounts, toString(id), 'pause'),
  activateAccount: (id) => join(baseRoutes.accounts, toString(id), 'activate'),
};

export const clientRoutes = {
  getClients: () => baseRoutes.clients,
  getClientById: (id) => join(baseRoutes.clients, toString(id)),
  createClient: () => baseRoutes.clients,
  updateClientById: (id) => join(baseRoutes.clients, toString(id)),
  deleteClientById: (id) => join(baseRoutes.clients, toString(id)),
  pauseClient: (id) => join(baseRoutes.clients, toString(id), 'pause'),
  activateClient: (id) => join(baseRoutes.clients, toString(id), 'activate'),
};

export const userRoutes = {
  getUsers: () => baseRoutes.users,
  getUserById: (id) => join(baseRoutes.users, toString(id)),
  createUser: () => baseRoutes.users,
  updateUserById: (id) => join(baseRoutes.users, toString(id)),
  deleteUserById: (id) => join(baseRoutes.users, toString(id)),
  updateUserEmail: (id) => join(baseRoutes.users, toString(id), 'email'),
  updateUserPassword: (id) => join(baseRoutes.users, toString(id), 'password'),
};

export const teamRoutes = {
  getTeams: () => baseRoutes.teams,
  getTeamById: (id) => join(baseRoutes.teams, toString(id)),
  createTeam: () => baseRoutes.teams,
  updateTeamById: (id) => join(baseRoutes.teams, toString(id)),
  deleteTeamById: (id) => join(baseRoutes.teams, toString(id)),
};

export const departmentRoutes = {
  getDepartments: () => baseRoutes.departments,
  getDepartmentById: (id) => join(baseRoutes.departments, toString(id)),
  createDepartment: () => baseRoutes.departments,
  updateDepartmentById: (id) => join(baseRoutes.departments, toString(id)),
  deleteDepartmentById: (id) => join(baseRoutes.departments, toString(id)),
};

export const positionRoutes = {
  getPositions: () => baseRoutes.positions,
  getPositionById: (id) => join(baseRoutes.positions, toString(id)),
  createPosition: () => baseRoutes.positions,
  updatePositionById: (id) => join(baseRoutes.positions, toString(id)),
  deletePositionById: (id) => join(baseRoutes.positions, toString(id)),
};

export const channelRoutes = {
  getChannels: () => baseRoutes.channels,
  getChannelById: (id) => join(baseRoutes.channels, toString(id)),
  createChannel: () => baseRoutes.channels,
  updateChannelById: (id) => join(baseRoutes.channels, toString(id)),
  deleteChannelById: (id) => join(baseRoutes.channels, toString(id)),
};

export const currencyRoutes = {
  getCurrencies: () => baseRoutes.currencies,
  getCurrencyById: (id) => join(baseRoutes.currencies, toString(id)),
};

export const outcomeRoutes = {
  getOutcome: () => baseRoutes.outcome,
};

export const spendRoutes = {
  getSpend: () => baseRoutes.spend,
};

export const targetRoutes = {
  /**
   *
   * @returns { String } route to get performance target via api
   */
  getPerformanceTarget: () => join(baseRoutes.target, 'performance'),
};

export const airbyteRoutes = {
  getDestinations: () => join(baseRoutes.airbyte, 'destinations'),
  getSources: () => join(baseRoutes.airbyte, 'sources'),
  deleteSource: (id) => join(baseRoutes.airbyte, 'sources', id),
};
