import { toNumber } from 'lodash';

export default class AirbyteSource {
  /**
   * Constructor function for creating an instance of the class.
   *
   * @param { Object  } data - The data object used to initialize the instance.
   * @param { string  } data.id - id.
   * @param { string  } data.name - name.
   * @param { number  } data.accountId - account id.
   * @param { number  } data.destinationId - destination id.
   * @param { string  } data.connectionId - connection id.
   * @param { Object  } data.destination - destination data.
   * @param { Object  } data.account - account data.
   */
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.accountId = toNumber(data.accountId);
    this.destinationId = toNumber(data.destinationId);
    this.connectionId = data.connectionId;
    this.destination = data.destination || {};
    this.account = data.account || {};
  }

  /**
   * Get source id.
   *
   * @return { string } source id (uuid).
   */
  getId() {
    return this.id;
  }

  /**
   * Get source name.
   *
   * @return { string } source name.
   */
  getName() {
    return this.name;
  }

  /**
   * Get the account ID.
   *
   * @return { number } The account ID.
   */
  getAccountId() {
    return this.accountId;
  }

  /**
   * Get the destination ID.
   *
   * @return { number } The destinationID.
   */
  getDestinationId() {
    return this.destinationId;
  }

  /**
   * Get the connection ID.
   *
   * @return { string } The connection ID (uuid).
   */
  getConnectionId() {
    return this.connectionId;
  }

  /**
   * Get destination.
   *
   * @return { import('./AirbyteDestination.js').default } The destination instance.
   */
  getDestination() {
    const Destination = require('./AirbyteDestination').default;
    return new Destination(this.destination);
  }

  /**
   * Get account.
   *
   * @return { import('./Account.js').default } The account instance.
   */
  getAccount() {
    const Account = require('./Account').default;
    return new Account(this.account);
  }
}
