import { toNumber } from 'lodash';
import ApiService from '../services/ApiService.js';

export default class Position {
  /**
   *
   * @param { Object } data - position data
   * @param { Number } data.id - id
   * @param { String } data.name - name
   * @param { Number|Null } data.departmentId - position department id
   * @param { Object|Null } data.department - position department
   * @param { Array<Object> } data.users - users with current position
   */
  constructor(data) {
    this.id = toNumber(data.id);
    this.name = data.name;
    this.departmentId = toNumber(data.departmentId);
    this.department = data.department || {};
    this.users = data.users || [];
  }

  /**
   * Get position id
   *
   * @returns { number } id
   */
  getId() {
    return this.id;
  }

  /**
   * Get position name
   *
   * @returns { string } name
   */
  getName() {
    return this.name;
  }

  /**
   * Get position department id
   *
   * @returns { number } department id
   */
  getDepartmentId() {
    return this.departmentId;
  }

  /**
   * Get position department
   *
   * @returns { import('./Department.js').default } department
   */
  getDepartment() {
    const Department = require('./Department.js').default;
    return new Department(this.department);
  }

  /**
   * Get users assigned position
   *
   * @returns { import('./User.js').default[] } users
   */
  getUsers() {
    const User = require('./User.js').default;
    return this.users.map((user) => new User(user));
  }

  /**
   * Returns the number of users in the array.
   *
   * @return {number} The number of users.
   */
  getUsersCount() {
    return this.users.length;
  }

  /**
   * Query all positions with from API
   *
   * @param { object } params - query params
   * @returns { Promise }
   */
  static queryAll(params = {}) {
    return ApiService.get('/positions', { related: true, ...params }).then(({ data }) => data.data);
  }

  /**
   * Query position by id from API
   *
   * @param { number|string } id - position id
   * @param { object } params - query params
   * @returns { Promise }
   */
  static queryById(id, params = {}) {
    return ApiService.get(`/positions/${id}`, { related: true, ...params }).then(({ data }) => data.data);
  }

  /**
   * Create a new position via API
   *
   * @param { object } body - body with new position data
   * @returns { Promise }
   */
  static create(body) {
    return ApiService.post('/positions', body).then(({ data }) => data.data);
  }

  /**
   * Update a position via API
   *
   * @param { number|string } id - position id
   * @param { object } body - body with position data update
   * @returns { Promise }
   */
  static update(body, id) {
    return ApiService.patch(`/positions/${id}`, body).then(({ data }) => data.data);
  }

  /**
   * Delete position by id
   * @param { number|string } id - position id
   * @returns { Promise }
   */
  static delete(id) {
    return ApiService.delete(`/positions/${id}`).then(({ data }) => data.data);
  }
}
