import dayjs from 'dayjs';
import { toNumber, range } from 'lodash';
import OutcomeDay from './OutcomeDay.js';

export default class OutcomeDayMap {
  /**
   *
   * @param { Array } outcomeData - days array
   * @param { import('./OutcomeMonth.js').default } month - days month
   */
  constructor(outcomeData, month) {
    this.month = month;
    this.days = new Map(outcomeData
      .map((day) => ([toNumber(day.day), new OutcomeDay(day, month)])));
  }

  /**
   *
   * @param { Number } dayOrderNumber - day order number
   * @returns { OutcomeDay } - day
   */
  getDay(dayOrderNumber) {
    const orderNumber = toNumber(dayOrderNumber);
    const defaultDay = new OutcomeDay({ day: orderNumber }, this.month);
    return this.days.get(orderNumber) || defaultDay;
  }

  /**
   *
   * @returns { OutcomeDay[] } - days array
   */
  getDaysList() {
    const daysCount = dayjs().set('month', this.month.getOrderNumber() - 1).daysInMonth();
    return range(1, daysCount + 1).map((orderNumber) => this.getDay(orderNumber));
  }

  /**
   *
   * @returns { import('./OutcomeDay.js').default[] } - days without manual outcome updates
   */
  getDaysWithoutOutcomeUpdates() {
    const date = dayjs();
    const currentMonthNumber = date.month() + 1;
    const monthOrderNumber = this.month.getOrderNumber();

    const outcomeDays = this.getDaysList();

    if (currentMonthNumber === monthOrderNumber) {
      return outcomeDays.slice(0, date.date()).filter((day) => !day.isOutcomeUpdated());
    }
    return outcomeDays.filter((day) => !day.isOutcomeUpdated());
  }

  /**
   *
   * @returns { Number } - days count without otcome updates
   */
  getDaysCountWithoutOutcomeUpdates() {
    return this.getDaysWithoutOutcomeUpdates().length;
  }
}
