import { toNumber } from 'lodash';
import ChannelSpendMonthMap from './ChannelSpendMonthMap.js';

export default class ChannelSpendYear {
  /**
   *
   * @param { Object } data spend year data
   * @param { Number|String } data.year year order number
   * @param { Number|String } data.target year spend target
   * @param { Number|String } data.spend year spend
   * @param { Array } data.months year spend months
   */
  constructor(data) {
    this.year = toNumber(data.year);
    this.target = toNumber(data.target || 0);
    this.spend = toNumber(data.spend || 0);
    this.months = new ChannelSpendMonthMap(data.months);
  }

  /**
   * @returns { Number } year ourder number
   */
  getYearOrderNumber() {
    return this.year;
  }

  /**
   * @returns { Number } year spend
   */
  getSpend() {
    return this.spend;
  }

  /**
   * @returns { Number } year target
   */
  getTarget() {
    return this.target;
  }

  /**
   *
   * @returns { ChannelSpendMonthMap }
   */
  getMonths() {
    return this.months;
  }

  /**
   *
   * @returns { Number } year deviation in percentages
   */
  getDeviationInPercentages() {
    return this.target === 0 ? 0 : (this.spend / this.target) * 100;
  }

  /**
   * Retrieves the current month's spend.
   *
   * @return { number } The spend for the current month.
   */
  getCurrenMonthSpend() {
    return this.months.getCurrentMonth().getSpend();
  }

  /**
   * Retrieves the current month.
   *
   * @return { import('./ChannelSpendMonth.js').default } The current month.
   */
  getCurrentMonth() {
    return this.months.getCurrentMonth();
  }
}
