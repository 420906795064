import { toNumber } from 'lodash';
import dayjs from 'dayjs';
import { buildServerAssetUrl } from '../helpers/assets.js';
import ApiService from '../services/ApiService.js';
import { userRoutes as apiRoutes } from '../helpers/apiRoutes.js';

export default class User {
  constructor(userData) {
    const {
      id,
      firstName,
      lastName,
      fullName,
      email,
      status,
      position,
      positionId,
      departmentId,
      clickupId,
      messengerId,
      primaryPhone,
      type,
      teams,
      department,
      projects,
      createdAt,
      updatedAt,
      role,
      avatarPath,
      googleId,
      googleAccount,
      hiringDate,
      roleId,
      teamSupervisor,
    } = userData;
    this.id = toNumber(id);
    this.firstName = firstName;
    this.lastName = lastName;
    this.fullName = fullName;
    this.email = email;
    this.type = type;
    this.status = status;
    this.position = position || {};
    this.primaryPhone = primaryPhone || {};
    this.teams = teams || [];
    this.department = department || {};
    this.projects = projects || [];
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.avatarPath = avatarPath;
    this.clickupId = clickupId;
    this.messengerId = messengerId;
    this.googleId = googleId;
    this.role = role || {};
    this.roleId = roleId;
    this.positionId = positionId;
    this.departmentId = departmentId;
    this.googleAccount = googleAccount || {};
    this.permissions = role || {};
    this.hiringDate = hiringDate;
    this.teamSupervisor = teamSupervisor || [];
  }

  /**
   * Checks if the user has access to the specified resource for the given actions.
   *
   * @param { string } resource - The resource to check access for.
   * @param { string[] } actions - The actions to check access for.
   * @return { boolean } Returns true if the user has access, false otherwise.
   */
  hasAccess(resource, ...actions) {
    return this.permissions.can(resource, actions);
  }

  /**
   * Checks if the user has administration access group (users, teams, departments, positions, roles, logs).
   *
   * @return { boolean } true if the user has administration access, otherwise false.
   */
  hasAdministrationAccess() {
    const access = 'read';
    return this.hasAccess('users', access)
      || this.hasAccess('teams', access)
      || this.hasAccess('departments', access)
      || this.hasAccess('positions', access)
      || this.hasAccess('roles', access)
      || this.hasAccess('logs', access);
  }

  /**
   * Determines if the user has application access group (projects, invoices, accounts).
   *
   * @return { boolean } true if the user has application access, false otherwise.
   */
  hasApplicationAccess() {
    const access = 'read';
    return this.hasAccess('projects', access)
      || this.hasAccess('invoices', access)
      || this.hasAccess('accounts', access);
  }

  /**
   * Returns the first name.
   *
   * @return { string } first name.
   */
  getFirstName() {
    return this.firstName;
  }

  /**
   * Retrieves the last name.
   *
   * @return { string } last name.
   */
  getLastName() {
    return this.lastName;
  }

  /**
   * Get the ID.
   *
   * @return { number } ID.
   */
  getId() {
    return toNumber(this.id);
  }

  /**
   * Retrieves the full name.
   *
   * @return { string } full name.
   */
  getFullName() {
    return this.fullName;
  }

  /**
   * Retrieves the email.
   *
   * @return { string } email.
   */
  getEmail() {
    return this.email;
  }

  /**
   * Get the roleId.
   *
   * @return { number|null } roleId or null.
   */
  getRoleId() {
    return this.roleId;
  }

  /**
   * Get the user status.
   *
   * @return { 'active'|'inactive'|'deleted' } status.
   */
  getStatus() {
    return this.status;
  }

  /**
   * Get user primaryPhone.
   *
   * @return { { number: string, countryCode: string }|null } primaryPhone.
   */
  getPrimaryPhone() {
    return this.primaryPhone;
  }

  /**
   * Get teams.
   *
   * @return { import('./Team.js').default[] } The teams.
   */
  getTeams() {
    const Team = require('./Team.js').default;
    return this.teams.map((team) => new Team(team));
  }

  /**
   * Get teams where current user is supervisor.
   *
   * @return { import('./Team.js').default[] } The teams.
   */
  getTeamsSupervisor() {
    const Team = require('./Team.js').default;
    return this.teamSupervisor.map((team) => new Team(team));
  }

  /**
   * Get user department.
   *
   * @return { import('./Department.js').default|null } department.
   */
  getDepartment() {
    const Department = require('./Department.js').default;
    return this.department ? new Department(this.department) : null;
  }

  /**
   * Get user projects.
   *
   * @return { Project[] } An array of project objects.
   */
  getProjects() {
    const Project = require('./Project.js').default;
    return this.projects.map((project) => new Project(project));
  }

  /**
   * Returns the count of projects.
   *
   * @return { number } The count of projects.
   */
  getProjectsCount() {
    return this.projects.length;
  }

  /**
   * Get date of creation.
   *
   * @return { import('dayjs').Dayjs|null } date or null.
   */
  getDateOfCreation() {
    return this.createdAt ? dayjs(this.createdAt) : null;
  }

  /**
   * Get date of update.
   *
   * @return { import('dayjs').Dayjs|null } date or null.
   */
  getDateOfUpdate() {
    return this.updatedAt ? dayjs(this.updatedAt) : null;
  }

  /**
   * Get user role.
   *
   * @return { import('./Role.js').default|null } user role.
   */
  getRole() {
    const Role = require('./Role.js').default;
    return new Role(this.role);
  }

  /**
   * Checks if a role is assigned to user.
   *
   * @return {boolean} Returns true if a role is assigned, otherwise false.
   */
  isRoleAssigned() {
    return !!this.roleId;
  }

  /**
   * Check if the position is assigned to user.
   *
   * @return {boolean} True if the position is assigned, false otherwise.
   */
  isPositionAssigned() {
    return !!this.positionId;
  }

  /**
   * Check if the department is assigned to user.
   *
   * @return {boolean} true if the department is assigned, false otherwise.
   */
  isDepartmentAssigned() {
    return !!this.departmentId;
  }

  /**
   * Checks if the user has a primary phone.
   *
   * @return { boolean } Returns true if the user has a primary phone, otherwise false.
   */
  hasPrimaryPhone() {
    return !!this.primaryPhone.id;
  }

  /**
   * Returns the avatar path for the user.
   *
   * @return { string } The avatar path.
   */
  getAvatarPath() {
    return buildServerAssetUrl(this.avatarPath);
  }

  /**
   * Get the Google ID.
   *
   * @return { string|number|null|undefined } The Google ID.
   */
  getGoogleId() {
    return this.googleId;
  }

  /**
   * Get the position ID.
   *
   * @return { number|null } The position ID.
   */
  getPositionId() {
    return this.positionId;
  }

  /**
   * Retrieves the user position.
   *
   * @return { import('./Position.js').default } The position if it is assigned, otherwise null.
   */
  getPosition() {
    const Position = require('./Position.js').default;
    return new Position(this.position);
  }

  getType() {
    return this.type;
  }

  getDepartmentId() {
    return this.departmentId;
  }

  getMessengerId() {
    return this.messengerId;
  }

  getClickupId() {
    return this.clickupId;
  }

  getGoogleAvatarPath() {
    return this.googleAccount.avatarPath;
  }

  getHiringDate() {
    return this.hiringDate ? dayjs(this.hiringDate) : null;
  }

  isAdmin() {
    return this.role.name === 'Administrator';
  }

  isEmployee() {
    return this.role.name === 'Employee';
  }

  /**
   * Checks if the user is active.
   *
   * @return { boolean } Returns true if the user is active, false otherwise.
   */
  isActive() {
    return this.status === 'active';
  }

  /**
   * Checks if the user is inactive.
   *
   * @return { boolean } Returns true if the user is inactive, false otherwise.
   */
  isInactive() {
    return this.status === 'inactive';
  }

  /**
   * Check if the user has access to the specified project.
   *
   * @param { number } projectId - The ID of the project to check access to
   * @return { boolean } Whether the user has access to the project
   */
  hasProjectAccess(projectId) {
    const hasProjectAccess = this.getProjects().some((project) => project.getId() === toNumber(projectId));
    const hasTeamProjectAccess = this.getTeams().flatMap((team) => team.getProjects())
      .some((project) => project.getId() === toNumber(projectId));

    return hasProjectAccess || hasTeamProjectAccess;
  }

  /**
   * Query all users with from API
   *
   * @param { object } params - query params
   * @returns { Promise }
   */
  static queryAll(params = {}) {
    return ApiService.get(apiRoutes.getUsers(), params).then(({ data }) => data.data);
  }

  /**
   * Query user by id from API
   *
   * @param { number|string } id - team id
   * @param { object } params - query params
   * @returns { Promise }
   */
  static queryById(id, params = {}) {
    return ApiService.get(apiRoutes.getUserById(id), params).then(({ data }) => data.data);
  }

  /**
   * Create a new user via API
   *
   * @param { object } body - body with new user data
   * @returns { Promise }
   */
  static create(body) {
    return ApiService.post(apiRoutes.createUser(), body).then(({ data }) => data.data);
  }

  /**
   * Update a user by id via API
   *
   * @param { number|string } id - user id
   * @param { object } body - body with user data update
   * @returns { Promise }
   */
  static update(id, body) {
    return ApiService.patch(apiRoutes.updateUser(id), body).then(({ data }) => data.data);
  }

  /**
   * Delete user by id
   *
   * @param { number|string } id - user id
   * @returns { Promise }
   */
  static delete(id) {
    return ApiService.delete(apiRoutes.deleteUser(id)).then(({ data }) => data.data);
  }
}
