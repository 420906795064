import { cloneDeep, toNumber } from 'lodash';
import Destination from '../../core/models/AirbyteDestination.js';
import Source from '../../core/models/AIrbyteSource.js';
import { airbyteRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    destinations: new Map(),
    sources: new Map(),
  },
  getters: {
    getDestinations: (state) => () => [...state.destinations.values()]
      .map((destination) => new Destination(cloneDeep(destination))),
    getSources: (state) => () => [...state.sources.values()]
      .map((source) => new Source(cloneDeep(source))),
    /**
     * Returns a ChannelSpend object for a given account ID and channel ID.
     *
     * @return { function({ id: Number|String }): Destination } The ChannelSpend object.
     */
    getDestinationById: (state) => (id) => {
      const account = state.accounts.get(toNumber(id)) || { id };
      return new Destination(cloneDeep(account));
    },
    getSourceById: (state) => (id) => {
      const source = state.sources.get(id) || { id };
      return new Source(cloneDeep(source));
    },
  },
  mutations: {
    setDestinations(state, destinations = []) {
      state.destinations = new Map(destinations.map((destination) => ([toNumber(destination.id), destination])));
    },
    setSources(state, sources = []) {
      state.sources = new Map(sources.map((source) => ([source.id, source])));
    },
    deleteSource(state, id) {
      state.sources.delete(id);
    },
    clearStore(state) {
      state.destinations.clear();
      state.sources.clear();
    },
  },
  actions: {
    async loadDestinations({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getDestinations(), { ...params });
      commit('setDestinations', data.data);
    },
    async loadSources({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getSources(), { related: true, ...params });
      commit('setSources', data.data);
    },
    async deleteSource({ commit }, id) {
      await apiService.delete(apiRoutes.deleteSource(id));
      commit('deleteSource', id);
    },
  },
});
