import { toNumber } from 'lodash';

export default class Department {
  /**
   *
   * @param { Object } data department data
   * @param { Number } data.id id
   * @param { String } data.name name
   * @param { Array<Object> } data.teams department teams
   * @param { Array<Object> } data.managers users who manage department
   * @param { Array<Object> } data.members department members
   * @param { Array<Object> } data.positions department positions
   */
  constructor(data) {
    this.id = toNumber(data.id);
    this.name = data.name;
    this.teams = data.teams || [];
    this.managers = data.managers || [];
    this.members = data.members || [];
    this.positions = data.positions || [];
  }

  /**
   * Get department id
   *
   * @returns { number } id
   */
  getId() {
    return this.id;
  }

  /**
   * Get departemnt name
   *
   * @returns { string } name
   */
  getName() {
    return this.name;
  }

  /**
   * Get teams under department
   *
   * @returns { import('./Team.js').default[] } teams
   */
  getTeams() {
    const Team = require('./Team.js').default;
    return this.teams.map((team) => new Team(team));
  }

  /**
   * Get department managers
   *
   * @returns { import('./User.js').default[] } managers
   */
  getManagers() {
    const User = require('./User.js').default;
    return this.managers.map((user) => new User(user));
  }

  /**
   * Checks if the object has any managers.
   *
   * @return { boolean } Returns true if the object has managers, false otherwise.
   */
  hasManagers() {
    return this.managers.length > 0;
  }

  /**
   * Returns the number of managers.
   *
   * @return { number } The number of managers.
   */
  getManagersCount() {
    return this.managers.length;
  }

  /**
   * Get department members
   *
   * @returns { import('./User.js').default[] } managers
   */
  getMembers() {
    const User = require('./User.js').default;
    return this.members.map((user) => new User(user));
  }

  /**
   * Check if the object has any members.
   *
   * @return {boolean} true if the object has members, false otherwise
   */
  hasMembers() {
    return this.members.length > 0;
  }

  /**
   * Returns the number of members in the group.
   *
   * @return { number } The number of members.
   */
  getMembersCount() {
    return this.members.length;
  }

  /**
   * Get positions under department
   *
   * @returns { import('./Position.js').default[] } positions
   */
  getPositions() {
    const Position = require('./Position.js').default;
    return this.positions.map((positon) => new Position(positon));
  }
}
