export default {
  list: {
    headers: {
      name: 'Account',
      id: 'ID',
      project: 'Project',
      channel: 'Channel',
      status: 'Status',
      airbyteConnection: 'Airbyte Connection',
      managedBy: 'Management',
      actions: 'Actions',
    },
    actions: {
      changeStatus: {
        tooltip: '{action} account',
      },
      edit: {
        tooltip: 'Edit account',
      },
      delete: {
        tooltip: 'Delete account',
        allert: {
          confirmation: 'Are you sure to delete this account?',
          notification: 'Account have been deleted successfully!',
        },
      },
    },
  },
  card: {
    project: {
      name: 'Project: {name}',
      manager: 'Project manager: {name}',
    },
    tabs: {
      overview: 'Overview',
      settings: 'Settings',
      airbyte: 'Airbyte',
    },
    spend: 'Spend',
  },
  overview: {
    header: 'Accounts Details',
    actions: {
      name: 'Actions',
      buttons: {
        edit: 'Edit',
        pause: 'Pause',
        delete: 'Delete',
      },
    },
    name: 'Name',
    id: 'Id',
    channel: 'Channel',
    project: 'Project',
  },
  new: {
    header: 'New Account',
    successConfirmation: 'Account has been created successfully!',
  },
  edit: {
    header: 'Edit Account',
    successConfirmation: 'Account has been udated successfully!',
  },
  error: {
    internalError: 'Something went wrong, try again!',
  },
  form: {
    name: {
      label: 'Name',
      placeholder: 'e.g. Lucky Strike',
    },
    id: {
      label: 'ID',
      placeholder: 'e.g. 123456789',
      tooltip: 'First delete airbyte sources',
    },
    project: {
      label: 'Project',
      placeholder: 'Select a project',
    },
    channel: {
      label: 'Channel',
      placeholder: 'Select a channel',
      tooltip: 'First delete airbyte sources',
    },
    status: {
      label: 'Status',
      placeholder: 'Select a status',
    },
    management: {
      label: 'Management',
      placeholder: 'Select account management',
    },
    airbyteConnect: {
      label: 'Connect Airbyte',
    },
    airbyteStartDate: {
      label: 'Airbyte Start Date',
      placeholder: 'Pick a date',
    },
    buttons: {
      reset: 'Discard',
      submit: 'Submit',
    },
  },
};
