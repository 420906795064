import { createStore } from 'vuex';
import ApiService from '../core/services/ApiService.js';

import initClickupStore from './modules/clickup.js';
import initUsersStore from './modules/users.js';
import initTeamsStore from './modules/teams.js';
import initClientsStore from './modules/clients.js';
import initDepartmentsStore from './modules/departments.js';
import initPositionsStore from './modules/positions.js';
import initRolesStore from './modules/roles.js';
import initChannelsStore from './modules/channels.js';
import initFinancialsStore from './modules/financials.js';
import initEventsStore from './modules/events.js';
import initAccountsStore from './modules/accounts.js';
import initProjectsStore from './modules/projects.js';
import initCurrenciesStore from './modules/currencies.js';
import initAirbyteStore from './modules/airbyte.js';

import loader from './modules/loader.js';
import auth from './modules/auth.js';
import body from './modules/body.js';
import breadcrumbs from './modules/breadcrumbs.js';
import config from './modules/config.js';

const clickup = initClickupStore(ApiService);
const events = initEventsStore(ApiService);
const users = initUsersStore(ApiService);
const teams = initTeamsStore(ApiService);
const clients = initClientsStore(ApiService);
const departments = initDepartmentsStore(ApiService);
const positions = initPositionsStore(ApiService);
const roles = initRolesStore(ApiService);
const channels = initChannelsStore(ApiService);
const financials = initFinancialsStore(ApiService);
const accounts = initAccountsStore(ApiService);
const projects = initProjectsStore(ApiService);
const currencies = initCurrenciesStore(ApiService);
const airbyte = initAirbyteStore(ApiService);

const store = createStore({
  modules: {
    auth,
    body,
    breadcrumbs,
    config,
    clickup,
    users,
    teams,
    clients,
    departments,
    positions,
    roles,
    accounts,
    projects,
    channels,
    events,
    loader,
    financials,
    currencies,
    airbyte,
  },
});

export default store;
