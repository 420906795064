import { toNumber } from 'lodash';

export default class BrandingTargetMonth {
  /**
   *
   * @param { Object } data - target data
   * @param { Number } data.id - target id
   * @param { Number } data.month - month order number
   * @param { Number } data.target - target
   * @param { Number } data.burst - target burst
   * @param { Number } data.alwaysOn - target always on
   * @param { import('./BrandingTargetYear.js').default } year budget year
   */
  constructor(data, year) {
    this.id = toNumber(data.id);
    this.year = year;
    this.orderNumber = toNumber(data.month);
    this.target = toNumber(data.target) || 0;
    this.burst = toNumber(data.burst) || 0;
    this.alwaysOn = toNumber(data.alwaysOn) || 0;
  }

  /**
   *
   * @returns { Number } budget month id
   */
  getId() {
    return this.id;
  }

  /**
   *
   * @returns { import("./BrandingTargetYear.js").default } taregt year instance of month
   */
  getYear() {
    return this.year;
  }

  /**
   *
   * @returns { Number } month target
   */
  getTarget() {
    return this.target;
  }

  /**
   * Retrieves the burst target.
   *
   * @return { Number} The burst target.
   */
  getBurstTarget() {
    return this.burst;
  }

  /**
   * Retrieves the always on target.
   *
   * @return { Number } The alwais on target
   */
  getAlwaysOnTarget() {
    return this.alwaysOn;
  }

  /**
   *
   * @returns { Number } target month order number
   */
  getMonthOrderNumber() {
    return this.orderNumber;
  }
}
