import { toNumber } from 'lodash';

export default class SpendDay {
  /**
   *
   * @param { Object } data spend day data
   * @param { Number|String } data.day day order number
   * @param { Number|String } data.target day spend target
   * @param { Number|String } data.spend day spend
   * @param { Array } data.days day spend days
   */
  constructor(data) {
    this.dayOrderNumber = toNumber(data.day);
    this.target = toNumber(data.target);
    this.spend = toNumber(data.spend);
  }

  /**
   * @returns { Number } day ourder number
   */
  getDayOrderNumber() {
    return this.dayOrderNumber;
  }

  /**
     * @returns { Number } day spend
     */
  getSpend() {
    return this.spend;
  }

  /**
     * @returns { Number } day target
     */
  getTarget() {
    return this.target;
  }

  /**
     *
     * @returns { Number } day deviation in percentages
     */
  getDeviationInPercentages() {
    return (this.spend / this.target) * 100;
  }
}
