import { toNumber } from 'lodash';
import SpendDayMap from './SpendDayMap.js';

export default class SpendMonth {
  /**
   *
   * @param { Object } data spend month data
   * @param { Number|String } data.month month order number
   * @param { Number|String } data.target month spend target
   * @param { Number|String } data.spend month spend
   * @param { Number|String } data.googleSpend month google spend
   * @param { Number|String } data.facebookSpend month facebook spend
   * @param { Array } data.days month spend days
   */
  constructor(data) {
    this.monthOrderNumber = toNumber(data.month);
    this.target = toNumber(data.target || 0);
    this.spend = toNumber(data.spend || 0);
    this.googleSpend = toNumber(data.googleSpend || 0);
    this.facebookSpend = toNumber(data.facebookSpend || 0);
    this.days = new SpendDayMap(data.days || []);
  }

  /**
   * @returns { Number } month ourder number
   */
  getMonthOrderNumber() {
    return this.monthOrderNumber;
  }

  /**
     * @returns { Number } month spend
     */
  getSpend() {
    return this.spend;
  }

  /**
     * @returns { Number } month target
     */
  getTarget() {
    return this.target;
  }

  /**
     * @returns { Number } month google spend
     */
  getGoogleSpend() {
    return this.googleSpend;
  }

  /**
     * @returns { Number } month facebook spend
     */
  getFacebookSpend() {
    return this.facebookSpend;
  }

  /**
   *
   * @returns { SpendDayMap }
   */
  getDays() {
    return this.days;
  }

  /**
     *
     * @returns { Number } month deviation in percentages
     */
  getDeviationInPercentages() {
    return this.target === 0 ? 0 : (this.spend / this.target) * 100;
  }
}
