import { toNumber } from 'lodash';
import dayjs from 'dayjs';
import SpendMonth from './SpendMonth.js';

export default class SpendMonthMap {
  months = new Map();

  /**
   *
   * @param { Array } monthsData months spend data of the year
   */
  constructor(monthsData = []) {
    this.months = new Map(monthsData.map((month) => ([toNumber(month.month), new SpendMonth(month)])));
  }

  /**
   *
   * @param { Number|String } monthOrderNumber month order number
   * @returns { SpendMonth } spend month for given month order number
   */
  getMonth(monthOrderNumber) {
    return this.months.get(toNumber(monthOrderNumber)) || new SpendMonth({ month: monthOrderNumber });
  }

  /**
   *
   * @returns { SpendMonth } spend month for given month order number
   */
  getCurrentMonth() {
    const currentMonthNumber = dayjs().get('M') + 1;
    return this.months.get(currentMonthNumber) || new SpendMonth({ month: currentMonthNumber });
  }
}
