import { toNumber } from 'lodash';
import dayjs from 'dayjs';
import OutcomeYear from './OutcomeYear.js';

export default class Outcome {
  /**
   *
   * @param {{ projectId: Number|String , outcome: Array }} outcomeData - outcome data
   */
  constructor({ projectId, outcome = [] }) {
    this.projectId = toNumber(projectId);
    this.years = new Map(outcome.map((year) => ([toNumber(year.year), new OutcomeYear(year)])));
  }

  /**
   *
   * @returns { Number } project id
   */
  getProjectId() {
    return this.projectId;
  }

  /**
   *
   * @param { Number|String } year - year number
   * @returns { OutcomeYear } instance of outcome year
   */
  getYear(year) {
    const yearNumber = toNumber(year);
    return this.years.get(yearNumber) || new OutcomeYear({ year: yearNumber });
  }

  /**
   *
   * @returns { OutcomeYear } instance of outcome year
   */
  getCurrentYear() {
    const yearNumber = dayjs().get('year');
    return this.years.get(yearNumber) || new OutcomeYear({ year: yearNumber });
  }

  /**
   * Returns the previous year's OutcomeYear object from the years map.
   *
   * @return { OutcomeYear } The previous year's OutcomeYear object from the years map,
   * or a new OutcomeYear object with the year number if not found in the map.
   */
  getPreviousYear() {
    const yearNumber = dayjs().subtract(1, 'year').get('year');
    return this.years.get(yearNumber) || new OutcomeYear({ year: yearNumber });
  }

  /**
   *
   * @returns { Array.<OutcomeYear> } years array
   */
  getYearList() {
    return [...this.years.values()];
  }

  /**
   *
   * @returns { Array.<OutcomeYear> } years array with complete outcome (complete year outcome
   *  means that every month of an year contain outcome)
   */
  getCompleteYears() {
    return [...this.years.values()].filter((year) => year.isCompleteOutcome());
  }
}
