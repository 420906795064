import { toNumber } from 'lodash';
import FinancialDay from './FinancialDay.js';

export default class FinancialDayMap {
  /**
   *
   * @param {{
   *  performanceTargetDays: import('../target/PerformanceTargetDayMap.js').default,
   *  outcomeDays: import('../outcome/OutcomeDayMap.js').default,
   *  spendDays: import('../spend/SpendDayMap.js').default,
   * }} data
   */
  constructor(data) {
    this.performanceTargetDays = data.performanceTargetDays;
    this.outcomeDays = data.outcomeDays;
    this.spendDays = data.spendDays;
  }

  /**
   *
   * @param { Number|String } dayOrderNumber - day order number
   * @returns { FinancialDay }
   */
  getDay(dayOrderNumber) {
    const normalizedOrderNumber = toNumber(dayOrderNumber);
    return new FinancialDay({
      performanceTargetDay: this.performanceTargetDays.getDay(normalizedOrderNumber),
      outcomeDay: this.outcomeDays.getDay(normalizedOrderNumber),
      spendDay: this.spendDays.getDay(normalizedOrderNumber),
      dayOrderNumber: normalizedOrderNumber,
    });
  }
}
