import objectPath from 'object-path';
import merge from 'deepmerge';
import layoutConfig from '../../core/config/defaultLayoutConfig.js';

export default {
  state: {
    config: layoutConfig,
    initial: layoutConfig,
  },
  getters: {
    layoutConfig(state) {
      return (path, defaultValue) => objectPath.get(state.config, path, defaultValue);
    },
  },
  mutations: {
    setLayoutConfig(state, payload) {
      state.config = payload;
    },
    resetLayoutConfig(state) {
      state.config = { ...state.initial };
    },
    overrideLayoutConfig(state) {
      const config = {
        ...state.initial,
        ...JSON.parse(window.localStorage.getItem('config') || '{}'),
      };
      state.config = config;
      state.initial = config;
    },
    overridePageLayoutConfig(state, payload) {
      state.config = merge(state.config, payload);
    },
  },
};
