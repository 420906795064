import { toNumber } from 'lodash';
import ChannelSpendDay from './ChannelSpendDay.js';

export default class ChannelSpendDayMap {
  days = new Map();

  /**
   *
   * @param { Array } daysData days spend data of the month
   */
  constructor(daysData = []) {
    this.days = new Map(daysData.map((day) => ([toNumber(day.day), new ChannelSpendDay(day)])));
  }

  /**
   *
   * @param { Number|String } dayOrderNumber day order number
   * @returns { ChannelSpendDay } spend day for given day order number
   */
  getDay(dayOrderNumber) {
    return this.days.get(toNumber(dayOrderNumber));
  }
}
