import { toNumber } from 'lodash';

export default class FinancialDay {
  /**
   *
   * @param {{
   *  performanceTargetDay: import('../target/PerformanceTargetDay.js').default,
   *  outcomeDay: import('../outcome/OutcomeDay.js').default,
   *  spendDay: import('../spend/SpendDay.js').default,
   *  dayOrderNumber: Number|String,
   * }} data
   */
  constructor(data) {
    this.performanceTargetDay = data.performanceTargetDay;
    this.outcomeDay = data.outcomeDay;
    this.spendDay = data.spendDay;
    this.orderNumber = toNumber(data.dayOrderNumber);
  }

  /**
   * @returns { Number } day order number
   */
  getOrderNumber() {
    return this.orderNumber;
  }

  get performanceTarget() {
    return this.performanceTargetDay;
  }

  get outcome() {
    return this.outcomeDay;
  }

  get spend() {
    return this.spendDay;
  }
}
