import { toNumber } from 'lodash';
import dayjs from 'dayjs';
import TargetMonth from './BrandingTargetMonth.js';

export default class BrandingTargetMonthMap {
  /**
   *
   * @param { Object[] } months - months row data
   * @param { import("./BrandingTargetYear.js").default } year budget year instance
   */
  constructor(months, year) {
    this.year = year;
    this.months = new Map(months.map((month) => ([toNumber(month.month), new TargetMonth(month, year)])));
  }

  /**
   * Get branding target month instance
   *
   * @param { Number|String } monthOrderNumber - month order number
   * @returns { TargetMonth } budget month instance
  */
  getMonth(monthOrderNumber) {
    const normalizedMonthOrderNumber = toNumber(monthOrderNumber);
    return this.months
      .get(normalizedMonthOrderNumber) || new TargetMonth({ month: normalizedMonthOrderNumber }, this.year);
  }

  /**
   *
   * @returns { TargetMonth[] } return target months list
  */
  getMonthsList() {
    return [...this.months.values()];
  }

  /**
   *
   * @returns { TargetMonth } current month target
   */
  getCurrentMonth() {
    const currentMonthNumber = dayjs().get('month') + 1;
    return this.months.get(currentMonthNumber) || new TargetMonth({ month: currentMonthNumber }, this.year);
  }
}
