import { h } from 'vue';

import AppIconTemplate from './AppIconTemplate.vue';

const icons = [
  { name: 'ArrowDownRight', icon: 'bi-arrow-down-right' },
  { name: 'ArrowDownShort', icon: 'bi-arrow-down-short' },
  { name: 'ArrowUpShort', icon: 'bi-arrow-up-short' },
  { name: 'Check', icon: 'bi-check' },
  { name: 'ChevronDown', icon: 'bi-chevron-down' },
  { name: 'ChevronRight', icon: 'bi-chevron-right' },
  { name: 'ChevronDoubleRight', icon: 'bi-chevron-double-right' },
  { name: 'ChevronDoubleLeft', icon: 'bi-chevron-double-left' },
  { name: 'Clipboard', icon: 'bi-clipboard' },
  { name: 'ClipboardCheck', icon: 'bi-clipboard-check' },
  { name: 'Email', icon: 'bi-envelope' },
  { name: 'EmailFill', icon: 'bi-envelope-fill' },
  { name: 'ExclamationTriangle', icon: 'bi-exclamation-triangle' },
  { name: 'ExclamationCircle', icon: 'bi-exclamation-circle' },
  { name: 'PencilFill', icon: 'bi-pencil-fill' },
  { name: 'Pencil', icon: 'bi-pencil' },
  { name: 'PencilSquare', icon: 'bi-pencil-square' },
  { name: 'PersonAdd', icon: 'bi-person-add' },
  { name: 'PersonVCard', icon: 'bi-person-vcard' },
  { name: 'PhoneFill', icon: 'bi-telephone-fill' },
  { name: 'Person', icon: 'bi-person' },
  { name: 'Plus', icon: 'bi-plus' },
  { name: 'Search', icon: 'bi-search' },
  { name: 'ThreeDots', icon: 'bi-three-dots' },
  { name: 'Toggles', icon: 'bi-toggles' },
  { name: 'Trash', icon: 'bi-trash' },
  { name: 'X', icon: 'bi-x' },
  { name: 'XLarge', icon: 'bi-x-lg' },
  { name: 'Download', icon: 'bi-download' },
  { name: 'Dash', icon: 'bi-dash' },
  { name: 'DashLg', icon: 'bi-dash-lg' },
  { name: 'GridFill', icon: 'bi-grid-fill' },
  { name: 'People', icon: 'bi-people' },
  { name: 'PersonBadge', icon: 'bi-person-badge' },
  { name: 'PlayCircle', icon: 'bi-play-circle' },
  { name: 'PauseCircle', icon: ' bi-pause-circle' },
  { name: 'CaretRightFill', icon: 'bi-caret-right-fill' },
  { name: 'XCircle', icon: 'bi-x-circle' },
  { name: 'ArrowRepeat', icon: 'bi-arrow-repeat' },
];

export default (app) => icons.forEach(({
  name, icon, isCustom, component,
}) => {
  const componentName = `App${name}Icon`;
  if (isCustom) {
    app.component(componentName, component);
  } else {
    app.component(
      componentName,
      {
        components: { AppIconTemplate },
        setup() {
          return () => h(AppIconTemplate, { class: icon });
        },
      },
    );
  }
});
