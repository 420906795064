import { toNumber } from 'lodash';
import { projectRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';
import Financials from '../../core/models/financials/Financials.js';
import BrandingTarget from '../../core/models/target/BrandingTarget.js';
import PerformanceTarget from '../../core/models/target/PerformanceTarget.js';
import Outcome from '../../core/models/outcome/Outcome.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    outcome: new Map(),
    performanceTarget: new Map(),
    brandingTarget: new Map(),
    spend: new Map(),
  },
  getters: {
    /**
     * Retrieves the financial data for a specific project by its ID.
     *
     * @return { () => Financials } The financial data for the specified project.
     */
    getFinancialsByProjectId: (state) => (projectId) => {
      const outcome = state.outcome.get(toNumber(projectId)) || [];
      const performanceTarget = state.performanceTarget.get(toNumber(projectId)) || [];
      const brandingTarget = state.brandingTarget.get(toNumber(projectId)) || [];
      const spend = state.spend.get(toNumber(projectId)) || [];

      return new Financials({
        projectId,
        outcome,
        performanceTarget,
        brandingTarget,
        spend,
      });
    },
    getBrandingTargetByProjectId: (state) => (projectId) => {
      const target = state.brandingTarget.get(toNumber(projectId)) || [];
      return new BrandingTarget({ projectId, target });
    },
    getPerformanceTargetByProjectId: (state) => (projectId) => {
      const target = state.performanceTarget.get(toNumber(projectId)) || [];
      return new PerformanceTarget({ projectId, target });
    },
    getOutcomeByProjectId: (state) => (projectId) => {
      const outcome = state.outcome.get(toNumber(projectId)) || [];
      return new Outcome({ projectId, outcome });
    },
  },
  mutations: {
    clearByProjectId(state, projectId) {
      state.outcome.delete(toNumber(projectId));
      state.spend.delete(toNumber(projectId));
      state.performanceTarget.delete(toNumber(projectId));
      state.brandingTarget.delete(toNumber(projectId));
    },
    setOutcome(state, { projectId, outcomeData }) {
      state.outcome.set(toNumber(projectId), outcomeData);
    },
    setSpend(state, { projectId, spendData }) {
      state.spend.set(toNumber(projectId), spendData);
    },
    setPerformanceTarget(state, { projectId, targetData }) {
      state.performanceTarget.set(toNumber(projectId), targetData);
    },
    setBrandingTarget(state, { projectId, targetData }) {
      state.brandingTarget.set(toNumber(projectId), targetData);
    },
    clearStore() {
      this.state.outcome.clear();
      this.state.spend.clear();
      this.state.performanceTarget.clear();
      this.state.brandingTarget.clear();
    },
  },
  actions: {
    async loadFinancialsByProjectId({ dispatch }, { projectId, params = {} }) {
      dispatch('loadOutcome', { projectId, params });
      dispatch('loadSpend', { projectId, params });
      dispatch('loadPerformanceTarget', { projectId, params });
      dispatch('loadBrandingTarget', { projectId, params });
    },
    async loadOutcome({ commit }, { projectId, ...params }) {
      const { data } = await apiService.get(apiRoutes.getOutcome(projectId), { includeUpdates: true, ...params });
      commit('setOutcome', { projectId, outcomeData: data.data });
    },
    async loadSpend({ commit }, { projectId, ...params }) {
      const { data } = await apiService.get(apiRoutes.getSpend(projectId), { ...params });
      commit('setSpend', { projectId, spendData: data.data });
    },
    async loadPerformanceTarget({ commit }, { projectId, params = {} }) {
      const { data } = await apiService
        .get(apiRoutes.getPerformanceTarget(projectId), { includeUpdates: true, ...params });
      commit('setPerformanceTarget', { projectId, targetData: data.data });
    },
    async loadBrandingTarget({ commit }, { projectId, params = {} }) {
      const { data } = await apiService
        .get(apiRoutes.getBrandingTarget(projectId), { includeUpdates: true, ...params });
      commit('setBrandingTarget', { projectId, targetData: data.data });
    },
    async updateOutcome({ dispatch }, { projectId, body }) {
      await apiService.patch(apiRoutes.updateOutcome(projectId), body);
      dispatch('loadOutcome', { projectId });
    },
    async createPerformanceTarget({ dispatch }, { projectId, body }) {
      await apiService.post(apiRoutes.createPerformanceTarget(projectId), body);
      dispatch('loadPerformanceTarget', { projectId });
    },
    async updatePerformanceTarget({ dispatch }, { projectId, body }) {
      await apiService.patch(apiRoutes.updatePerformanceTarget(projectId), body);
      dispatch('loadPerformanceTarget', { projectId });
    },
    async deletePerformanceTarget({ dispatch }, { projectId, year }) {
      await apiService.delete(apiRoutes.deletePerformanceTarget(projectId, year));
      dispatch('loadPerformanceTarget', { projectId });
    },
    async updateBrandingTarget({ dispatch }, { projectId, body }) {
      await apiService.patch(apiRoutes.updateBrandingTarget(projectId), body);
      dispatch('loadBrandingTarget', { projectId });
    },
    async createBrandingTarget({ dispatch }, { projectId, body }) {
      await apiService.post(apiRoutes.createBrandingTarget(projectId), body);
      dispatch('loadBrandingTarget', { projectId });
    },
  },
});
