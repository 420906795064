import { round, sumBy, toNumber } from 'lodash';
import TargetDayMap from './PerformanceTargetDayMap.js';

export default class TargetMonth {
  /**
   *
   * @param { Object } monthData budget month data
   * @param { Number } monthData.id month budget id
   * @param { Number } monthData.month month budget order number
   * @param { Number } monthData.target month budget target
   * @param { Number|Null } monthData.kpi month kpi
   * @param { Object } monthData.updates month budget updates
   * @param { Array } monthData.updates.target month budget target updates
   * @param { Array } monthData.updates.kpi month budget kpi updates
   * @param { import('./PerformanceTargetYear.js').default } year budget year
   */
  constructor({
    id,
    month,
    target = 0,
    kpi = null,
    updates = { target: [], kpi: [] },
  }, year) {
    this.id = toNumber(id);
    this.year = year;
    this.orderNumber = toNumber(month);
    this.target = toNumber(target) || 0;
    this.kpi = kpi;
    this.updates = updates;
    this.days = new TargetDayMap(this);
  }

  /**
   *
   * @returns { Number } budget month id
   */
  getId() {
    return this.id;
  }

  /**
   *
   * @returns { import("./PerformanceTargetYear.js").default } budget year instance of month
   */
  getYear() {
    return this.year;
  }

  /**
   *
   * @returns { Number } month budget target
   */
  getTarget() {
    return this.target;
  }

  /**
   *
   * @returns { Array } month budget target updates
   */
  getTargetUpdates() {
    return this.updates.target || [];
  }

  /**
   *
   * @returns { Number|Null } month budget kpis
   */
  getKpi() {
    return this.kpi;
  }

  /**
   *
   * @returns { Boolean }
   */
  hasKpi() {
    return !!this.kpi;
  }

  /**
   *
   * @returns { Boolean }
   */
  hasTarget() {
    return this.target > 0;
  }

  /**
   *
   * @returns { Array } month budget kpis updates
   */
  getKpiUpdates() {
    return this.updates.kpi || [];
  }

  /**
   *
   * @returns { Number } month budget order number
   */
  getOrderNumber() {
    return this.orderNumber;
  }

  /**
   *
   * @returns { TargetDayMap } target days
   */
  getDays() {
    return this.days;
  }

  /**
   *
   * @returns { Number } participation's the target of month in the target year
   */
  getTargetParticipation() {
    return round((this.getTarget() / this.year.getTarget()) * 100, 2);
  }

  /**
   *
   * @returns { Number } month turget up to date
   */
  getTargetUpToDate() {
    const currentDay = this.days.getCurrentDay();
    const days = this.days.getDaysList().filter((day) => day.getOrderNumber() < currentDay.getOrderNumber());
    return sumBy(days, (day) => day.getTarget());
  }
}
