import { toNumber, cloneDeep } from 'lodash';
import Team from '../../core/models/Team.js';
import { teamRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    teams: new Map(),
  },
  getters: {
    /**
     * Retrieves the teams from the state.
     *
     * @param { Object } state - The state map.
     * @return { function(): Team[] } - teams
     */
    getTeams: (state) => () => [...state.teams.values()].map((team) => new Team(cloneDeep(team))),
    /**
     * Retrieves a team from the state by its ID.
     *
     * @return { function(number): Team }  - The retrieved team object,
     * or a new team object if the team is not found.
     */
    getTeamById: (state) => (id) => {
      const team = state.teams.get(toNumber(id));
      return team ? new Team(cloneDeep(team)) : new Team({ id });
    },
  },
  mutations: {
    setTeams(state, teams) {
      state.teams = new Map(teams.map((team) => [toNumber(team.id), team]));
    },
    addTeam(state, team) {
      state.teams.set(toNumber(team.id), team);
    },
    deleteTeamById(state, id) {
      state.teams.delete(toNumber(id));
    },
    clearStore(state) {
      state.teams.clear();
    },

  },
  actions: {
    /**
     * Loads teams from the API and stores them in the store.
     *
     * @param { Object } commit - The commit object.
     */
    async loadTeams({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getTeams(), { related: true, ...params });
      commit('setTeams', data.data);
    },
    /**
     * Loads team by id from the API and stores it in the store.
     *
     * @param { Object } commit - The commit function from the Vuex store.
     * @param { object } data - The data to delete team.
     * @param { number|string } data.id - The ID of the team to delete.
     * @param { object } data.params - The query parameters.
     */
    async loadTeamById({ commit }, { id, params = {} }) {
      const { data } = await apiService.get(apiRoutes.getTeamById(id), { related: true, ...params });
      commit('addTeam', data.data);
    },
    /**
     * Delete a team by id via API.
     *
     * @param { Object } commit - The commit object.
     * @param { string|number } id - The ID of the team to delete.
     */
    async deleteTeamById({ commit }, id) {
      await apiService.delete(apiRoutes.deleteTeamById(id));
      commit('deleteTeamById', id);
    },
    /**
     * Creates a team using the given body.
     *
     * @param { Object } dispatch - The dispatch function from Vuex.
     * @param { Object } body - The body of the request containing the team data.
     */
    async createTeam({ dispatch }, body) {
      const { data } = await apiService.post(apiRoutes.createTeam(), body);
      dispatch('loadTeamById', { id: data.data.id });
    },
    /**
     * Updates a team by its ID.
     *
     * @param { Object } dispatch - The dispatch function from Vuex.
     * @param { Object } data - The data to update.
     * @param { number|string } data.id - The ID of the team to update.
     * @param { object } data.body - The body of the request containing the team new data.
     */
    async updateTeamById({ dispatch }, { id, body }) {
      const { data } = await apiService.patch(apiRoutes.updateTeamById(id), body);
      dispatch('loadTeamById', { id: data.data.id });
    },
  },
});
