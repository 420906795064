export default {
  sources: {
    list: {
      headers: {
        name: 'Source',
        connections: 'Connection',
        status: 'Connection Status',
        actions: 'Actions',
      },
      actions: {
        enable: {
          tooltip: 'Enable connection',
        },
        pause: {
          tooltip: 'Pause connection',
        },
        delete: {
          tooltip: 'Delete source',
          confirmation: 'Are you sure to delete this source?',
          notification: 'The source has been successfully deleted!',
        },
      },
    },
  },
};
