<template>
  <img
    v-if="isRenderImg"
    :src="url"
    alt="user avatar"
    :style="iconStyle"
    class="border border-white"
    @error="onError"
  >
  <svg
    v-else
    :style="iconStyle"
  >
    <text
      x="50%"
      y="50%"
      text-anchor="middle"
      dominant-baseline="central"
      :style="textStyle"
    >
      {{ avatarTitle }}
    </text>
  </svg>
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import { buildServerAssetUrl } from '../core/helpers/assets.js';

const props = defineProps({
  avatarPath: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: 'Avatar Title',
    required: true,
  },
  size: {
    type: Number,
    default: 50,
  },
  rounded: {
    type: Number,
    default: 0,
  },
});

const isRenderImg = ref(!!props.avatarPath);
const url = computed(() => (props.avatarPath.startsWith('https')
  ? props.avatarPath
  : buildServerAssetUrl(props.avatarPath)));

/**
 * generate avatar title(two uppercase letters)
 */
const avatarTitle = computed(() => {
  const words = props.title.split(' ');
  let title = words[0][0];

  if (!words[1]) {
    title += props.title[1];
  } else {
    title += words[1][0];
  }

  return title.toUpperCase();
});

const iconStyle = computed(() => {
  // use hsl to define the background color.
  const firstnameLetterIndex = avatarTitle.value.charCodeAt(0) - 64;
  const lastnameLetterIndex = avatarTitle.value.charCodeAt(1) - 64;

  const hue = (360 / 26) * (firstnameLetterIndex - lastnameLetterIndex);
  const backgroundColor = `hsl(${hue}, 78%, 70%, 60%)`;

  return {
    width: `${props.size}px`,
    height: `${props.size}px`,
    'border-radius': `${props.rounded}%`,
    background: backgroundColor,
  };
});
const textStyle = computed(() => ({
  fill: 'rgba(255, 255, 255, .7)',
  fontSize: `${props.size * 0.3}px`,
}));

const onError = () => {
  isRenderImg.value = false;
};

watch(
  () => props.avatarPath,
  (value) => {
    isRenderImg.value = !!value;
  },
);
</script>
