import { toNumber } from 'lodash';
import TargetMonthMap from './BrandingTargetMonthMap.js';

export default class BrandingTargetYear {
  /**
   *
   * @param {{
   *  year: Number|String,
   *  target: Number|String,
   *  alwaysOn: Number|String,
   *  burst: Number|String,
   *  months: Array,
   * }} data - target year data
   */
  constructor(data) {
    this.yearOrderNumber = toNumber(data.year);
    this.target = toNumber(data.target) || 0;
    this.burst = toNumber(data.burst) || 0;
    this.alwaysOn = toNumber(data.alwaysOn) || 0;
    this.months = new TargetMonthMap(data.months || [], this);
  }

  /**
   *
   * @returns { Number } yaer number
   */
  getYearOrderNumber() {
    return this.yearOrderNumber;
  }

  /**
   *
   * @returns { Number } year target
   */
  getTarget() {
    return this.target;
  }

  /**
   * Retrieves the burst target.
   *
   * @return { Number } The burst target.
   */
  getBurstTarget() {
    return this.burst;
  }

  /**
   * Retrieves the burst always on.
   *
   * @return { Number } The always on target.
   */
  getAlwaysOnTarget() {
    return this.alwaysOn;
  }

  /**
   *
   * @returns { TargetMonthMap } current year months map
   */
  getMonths() {
    return this.months;
  }
}
