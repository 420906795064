import { round, toNumber } from 'lodash';
import dayjs from 'dayjs';
import OutcomeDayMap from './OutcomeDayMap.js';

export default class OutcomeMonth {
  /**
   *
   * @param { Object } data - month data
   * @param { Number } data.month - month order number
   * @param { Number= } data.outcome - analytics project outcome
   * @param { Number= } data.actualOutcome - actual project outcome
   *  (that calculated base of manual updates and analytics outcome)
   * @param { Array[]= } data.days - month days
   * @param { import('./OutcomeYear.js').default } year - month year
   */
  constructor({
    month,
    outcome = 0,
    actualOutcome = 0,
    days = [],
  }, year) {
    const numberRound = toNumber(process.env.VUE_APP_NUMBER_ROUND);

    this.year = year;
    this.orderNumber = toNumber(month);
    this.outcome = round(toNumber(outcome), numberRound);
    this.actualOutcome = round(toNumber(actualOutcome), numberRound);
    this.days = new OutcomeDayMap(days, this);
  }

  /**
   *
   * @returns { import('./OutcomeYear.js').default } - return month year
   */
  getYear() {
    return this.year;
  }

  /**
   *
   * @returns { Number } - month order number
   */
  getOrderNumber() {
    return this.orderNumber;
  }

  /**
   *
   * @returns { Number } - project analytics outcome
   */
  getAnalyticsOutcome() {
    return this.outcome;
  }

  /**
   *
   * @returns { Number } - project actual outcome
   */
  getActualOutcome() {
    return this.actualOutcome;
  }

  /**
   *
   * @returns { OutcomeDayMap } - month days
   */
  getDays() {
    return this.days;
  }

  /**
   *
   * @returns { Number } - month's outcome participation in year
   */
  getParticipation() {
    const numberRound = toNumber(process.env.VUE_APP_NUMBER_ROUND);
    const yearOutcome = this.year.getActualOutcome();
    return round((this.getActualOutcome() / yearOutcome) * 100, numberRound);
  }

  /**
   *
   * @returns { Boolean } - is month's outcome more than 0
   */
  isCompleteOutcome() {
    return this.actualOutcome > 0 || this.outcome > 0;
  }

  /**
   *
   * @returns { Boolean } - is all days of months has outcome manually updated
   */
  isOutcomeUpdated() {
    const date = dayjs();
    const currentMonthNumber = date.month() + 1;
    const daysList = this.days.getDaysList();

    if (currentMonthNumber === this.orderNumber) {
      return daysList.splice(0, date.date()).every((day) => day.isOutcomeUpdated());
    }
    return daysList.every((day) => day.isOutcomeUpdated());
  }
}
