import ElementPlus from 'element-plus';
import { createApp } from 'vue';

import VueNumber from 'vue-number-animation';
import App from './App.vue';
import router from './router/index.js';
import store from './store';

// imports for app initialization
import ApiService from './core/services/ApiService.js';
import i18n from './core/plugins/i18n.js';
import initApexCharts from './core/plugins/apexcharts.js';
import initInlineSvg from './core/plugins/inline-svg.js';
import initVeeValidate from './core/plugins/veeValidate.js';
import initCasl from './core/plugins/casl.js';
import Logger from './core/plugins/Logger.js';

import setupErrorTracker from './core/plugins/errorTracker.js';

// import global components
import AppAvatar from './components/AppAvatar.vue';
import AppButton from './components/AppButton.vue';
import AppClipboardCopy from './components/AppClipboardCopy.vue';
import AppLoader from './components/AppLoader.vue';
import AppBadge from './components/AppBadge.vue';
import AppFinancialInfo from './components/AppFinancialInfo.vue';
import AppSearchBar from './components/AppSearchBar.vue';
import setupIcons from './components/icons/index.js';

import './core/plugins/prismjs.js';
import 'bootstrap';
import '@vuepic/vue-datepicker/dist/main.css';

const runApp = () => {
  const app = createApp(App);
  app.config.devtools = true;
  const environment = process.env.NODE_ENV;
  const isDevelopment = environment === 'development';
  const isProduction = environment === 'production';

  Logger.setConfig({
    enabled: true,
    log: isDevelopment,
    info: isDevelopment || isProduction,
    debug: isDevelopment,
    warn: isDevelopment,
    error: isDevelopment,
  });

  setupErrorTracker({ app, router, environment });

  app.use(store);
  app.use(router);
  app.use(ElementPlus);
  app.use(VueNumber);
  app.use(i18n);

  app.component('AppAvatar', AppAvatar);
  app.component('AppButton', AppButton);
  app.component('AppClipboardCopy', AppClipboardCopy);
  app.component('AppLoader', AppLoader);
  app.component('AppBadge', AppBadge);
  app.component('AppFinancialInfo', AppFinancialInfo);
  app.component('AppSearchBar', AppSearchBar);

  ApiService.init(app);
  initApexCharts(app);
  initInlineSvg(app);
  initVeeValidate();
  initCasl(app);
  setupIcons(app);

  app.mount('#app');
};

runApp();
