<template>
  <div class="bg-white rounded p-8 d-flex justify-content-between align-items-center h-70px mb-5">
    <div class="d-flex justify-content-start align-items-center">
      <!--begin::Input-->
      <el-input
        v-model="filterValue"
        clearable
        size="large"
        class="w-250px py-2"
        :placeholder="`Search by ${filterType}`"
        @input="updateSearch"
      >
        <template #suffix>
          <el-icon class="el-input__icon">
            <app-search-icon :size="20" />
          </el-icon>
        </template>
      </el-input>
      <!--end::Input-->

      <!--begin::Dropdown-->
      <el-dropdown
        id="filter-dropdown"
        trigger="click"
        class="ms-2"
      >
        <el-button size="large">
          <div class="d-flex align-items-center">
            <span>{{ filterType }}</span>
            <app-chevron-down-icon
              :size="15"
              class="ms-2"
            />
          </div>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu
            id="dropdown-filter-menu"
            ref="dropdownMenuRef"
            type="button"
          >
            <template
              v-for="(field, index) of searchFields"
              :key="index"
            >
              <el-dropdown-item
                :class="{ 'bg-light-primary': field === filterType }"
                @click="setSearchType"
              >
                {{ field }}
              </el-dropdown-item>
            </template>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    <!--end::Dropdown-->
    </div>
    <slot />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
  searchFields: {
    type: Array,
    default() {
      return [];
    },
  },
});

const emits = defineEmits({
  updateSearch: ({ value, type }) => typeof value === 'string' && typeof type === 'string',
});

const filterType = ref('');
const filterValue = ref('');

const updateSearch = (value) => {
  emits('updateSearch', { value, type: filterType.value });
};

const setSearchType = (event) => {
  event.preventDefault();
  const { target } = event;

  filterValue.value = '';
  filterType.value = target.textContent;

  emits('updateSearch', { value: '', type: target.textContent });
};

onMounted(() => {
  const [field = ''] = props.searchFields;
  filterType.value = field;
});
</script>
