<template>
  <div
    class="border border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
    :class="{ [`border-${borderColor}`]: true}"
  >
    <div class="d-flex align-items-center">
      <slot
        v-if="showIcon"
        name="icon"
      />
      <span
        v-if="!isAnimate"
        :class="statisticClasses"
      >
        {{ statistic }}
      </span>
      <number
        v-else
        :class="statisticClasses"
        :from="round(toNumber(statistic) / 2)"
        :to="statistic"
        :duration="2"
        easing="Power1.easeOut"
        tag="span"
        :format="format"
      />
      <span
        v-if="!prefix && !!postfix"
        :class="statisticClasses"
        class="ms-2"
      >
        {{ postfix }}
      </span>
    </div>
    <div class="fw-semobold fs-6 text-gray-400">
      {{ description }}
    </div>
  </div>
</template>

<script setup>
import { round, toNumber } from 'lodash';

const props = defineProps({
  statistic: {
    type: [Number, String],
    required: true,
  },
  prefix: {
    type: String,
    default: '',
  },
  postfix: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  isAnimate: {
    type: Boolean,
    default: false,
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  borderColor: {
    type: String,
    default: 'gray-300',
  },
  isCurrency: {
    type: Boolean,
    default: false,
  },
  currency: {
    type: Object,
    default() {
      return {
        code: 'EUR',
      };
    },
  },
});

const format = (value) => new Intl.NumberFormat(
  'gr-GR',
  props.isCurrency ? { style: 'currency', currency: props.currency?.code } : {},
).format(value);

const statisticClasses = 'fw-bold fs-2';
</script>
