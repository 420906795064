import { toNumber, cloneDeep } from 'lodash';
import Department from '../../core/models/Department.js';
import { departmentRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    departments: new Map(),
  },
  getters: {
    getDepartments: (state) => () => [...state.departments.values()]
      .map((department) => new Department(cloneDeep(department))),
    getDepartmentById: (state) => (id) => {
      const department = state.departments.get(toNumber(id)) || { id };
      return new Department(cloneDeep(department));
    },
  },
  mutations: {
    setDepartments(state, departments) {
      state.departments = new Map(departments.map((department) => [toNumber(department.id), department]));
    },
    addDepartment(state, department) {
      state.departments.set(toNumber(department.id), department);
    },
    deleteDepartmentById(state, id) {
      state.departments.delete(toNumber(id));
    },
    clearStore(state) {
      state.departments.clear();
    },
  },
  actions: {
    async loadDepartments({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getDepartments(), { related: true, ...params });
      commit('setDepartments', data.data);
    },
    async loadDepartmentById({ commit }, { id, params = {} }) {
      const { data } = await Department.queryById(apiRoutes.getDepartmentById(id), { related: true, ...params });
      commit('addDepartment', data.data);
    },
    async createDepartment({ dispatch }, body) {
      await apiService.post(apiRoutes.createDepartment(), body);
      dispatch('loadDepartments');
    },
    async updateDepartmentById({ dispatch }, { id, body }) {
      await apiService.patch(apiRoutes.updateDepartmentById(id), body);
      dispatch('loadDepartments');
    },
    async deleteDepartmentById({ commit }, id) {
      await apiService.delete(apiRoutes.deleteDepartmentById(id));
      commit('deleteDepartmentById', id);
    },
  },
});
