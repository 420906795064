import { toNumber } from 'lodash';

export default class SpendDay {
  /**
   *
   * @param { Object } data spend day data
   * @param { Number|String } data.day day order number
   * @param { Number|String } data.target day spend target
   * @param { Number|String } data.spend day spend
   * @param { Number|String } data.googleSpend day google spend
   * @param { Number|String } data.facebookSpend day facebook spend
   * @param { Array } data.days day spend days
   */
  constructor(data) {
    this.dayOrderNumber = toNumber(data.day);
    this.target = toNumber(data.target);
    this.spend = toNumber(data.spend);
    this.googleSpend = toNumber(data.googleSpend);
    this.facebookSpend = toNumber(data.facebookSpend);
  }

  /**
   * @returns { Number } day ourder number
   */
  getDayOrderNumber() {
    return this.dayOrderNumber;
  }

  /**
     * @returns { Number } day spend
     */
  getSpend() {
    return this.spend;
  }

  /**
     * @returns { Number } day target
     */
  getTarget() {
    return this.target;
  }

  /**
     * @returns { Number } day google spend
     */
  getGoogleSpend() {
    return this.googleSpend;
  }

  /**
     * @returns { Number } day facebook spend
     */
  getFacebookSpend() {
    return this.facebookSpend;
  }

  /**
     *
     * @returns { Number } day deviation in percentages
     */
  getDeviationInPercentages() {
    return (this.spend / this.target) * 100;
  }
}
