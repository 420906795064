import { toNumber } from 'lodash';
import dayjs from 'dayjs';

import FinancialMonth from './FinancialMonth.js';

export default class FinancialMonthMap {
  /**
   *
   * @param {{
   *  performanceTargetMonths: import('../target/PerformanceTargetMonthMap.js').default,
   *  brandingTargetMonths: import('../target/BrandingTargetMonthMap.js').default,
   *  outcomeMonths: import('../outcome/OutcomeMonthMap.js').default,
   *  spendMonths: import('../spend/SpendMonthMap.js').default,
   * }} data
   */
  constructor(data) {
    this.performanceTargetMonths = data.performanceTargetMonths;
    this.brandingTargetMonths = data.brandingTargetMonths;
    this.outcomeMonths = data.outcomeMonths;
    this.spendMonths = data.spendMonths;
  }

  /**
   *
   * @param { Number|String } monthOrderNumber - month order number
   * @returns { FinancialMonth }
   */
  getMonth(monthOrderNumber) {
    const normalizedOrderNumber = toNumber(monthOrderNumber);

    const outcomeMonth = this.outcomeMonths.getMonth(normalizedOrderNumber);
    const performanceTargetMonth = this.performanceTargetMonths.getMonth(normalizedOrderNumber);
    const brandingTargetMonth = this.brandingTargetMonths.getMonth(normalizedOrderNumber);
    const spendMonth = this.spendMonths.getMonth(normalizedOrderNumber);

    return new FinancialMonth({
      performanceTargetMonth,
      brandingTargetMonth,
      outcomeMonth,
      spendMonth,
      monthOrderNumber,
    });
  }

  getMonthsListWithOutcome() {
    return this.outcomeMonths.getMonthsList();
  }

  /**
   *
   * @returns { FinancialMonth }
   */
  getCurrentMonth() {
    const currentMonthNumber = dayjs().month() + 1;
    return this.getMonth(currentMonthNumber);
  }
}
