import { toNumber } from 'lodash';

export default class Vertical {
  /**
   *
   * @param { Object } data team data
   * @param { Number } data.id id
   * @param { String } data.name name
   * @param { Array<Object> } data.projects vertical's projects
   */
  // @ts-ignore
  constructor(data = {}) {
    this.id = toNumber(data.id);
    this.name = data.name;
    this.projects = data.projects || [];
  }

  /**
   * Get vertical id
   *
   * @returns { Number } vertical id
   */
  getId() {
    return this.id;
  }

  /**
   * Get vertiacl name
   *
   * @returns { String } vertical name
   */
  getName() {
    return this.name;
  }

  /**
   * Get verticals project count
   *
   * @returns { Number } projects count
   */
  getProjectsCount() {
    return this.projects.length;
  }

  /**
   * Get vertical's projects
   *
   * @returns { import('./Project.js').default[] } vertical's projects
   */
  getProjects() {
    const Project = require('./Project.js').default;
    return this.projects.map((project) => new Project(project));
  }
}
