import { toNumber, cloneDeep } from 'lodash';
import Position from '../../core/models/Position.js';
import { positionRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    positions: new Map(),
  },
  getters: {
    getPositions: (state) => () => [...state.positions.values()].map((position) => new Position(cloneDeep(position))),
    getPositionById: (state) => (id) => {
      const position = state.positions.get(toNumber(id)) || { id };
      return new Position(cloneDeep(position));
    },
  },
  mutations: {
    setPositions(state, positions) {
      state.positions = new Map(positions.map((position) => [toNumber(position.id), position]));
    },
    addPosition(state, data) {
      state.positions.set(toNumber(data.id), data);
    },
    deletePositionById(state, id) {
      state.positions.delete(toNumber(id));
    },
    clearStore(state) {
      state.positions.clear();
    },
  },
  actions: {
    async loadPositions({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getPositions(), { related: true, ...params });
      commit('setPositions', data.data);
    },
    async loadPositionById({ commit }, { id, params = {} }) {
      const { data } = await apiService.get(apiRoutes.getPositionById(id), { related: true, ...params });
      commit('addPosition', data.data);
    },
    async createPosition({ dispatch }, body) {
      await apiService.post(apiRoutes.createPosition(), body);
      dispatch('loadPositions');
    },
    async deletePositionById({ commit }, id) {
      await apiService.delete(apiRoutes.deletePositionById(id));
      commit('deletePositionById', id);
    },
    async updatePositionById({ dispatch }, { id, body }) {
      await apiService.patch(apiRoutes.updatePositionById(id), body);
      dispatch('loadPositions');
    },
  },
});
