import { toNumber } from 'lodash';

export default class TargetDay {
/**
 *
 * @param {{ dayOrderNumber: Number|String, target: Number|String }} data - target day data
 * @param { import('./PerformanceTargetMonth.js').default } month - target month
 */
  constructor({ dayOrderNumber, target }, month) {
    this.month = month;
    this.orderNumber = toNumber(dayOrderNumber);
    this.target = toNumber(target) || 0;
  }

  /**
   *
   * @returns { import('./PerformanceTargetMonth.js').default } target month
   */
  getMonth() {
    return this.month;
  }

  /**
   *
   * @returns { Number }
   */
  getTarget() {
    return this.target;
  }

  /**
   *
   * @returns { Number }
   */
  getOrderNumber() {
    return this.orderNumber;
  }
}
