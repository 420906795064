<template>
  <span :class="[{ badge: 'badge'}, contentTransformation, colorClass]">
    {{ content }}
  </span>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  content: {
    type: String,
    default: '',
  },
  contentTransformation: {
    type: String,
    default: 'text-capitalize',
  },
  color: {
    type: String,
    default: 'success',
  },
  isLight: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String,
    default: '',
  },
});

const badgeColor = computed(() => {
  if (props.status.length === 0) {
    return props.color;
  }
  switch (props.status) {
    case 'active':
      return 'success';
    case 'paused':
      return 'secondary';
    default:
      return 'primary';
  }
});
const colorClass = computed(() => ['badge', (props.isLight ? 'light' : ''), badgeColor.value]
  .filter((value) => value.length > 0)
  .join('-'));
</script>
