import { toNumber } from 'lodash';
import Source from './AIrbyteSource.js';

export default class AirbyteDestination {
  /**
   *
   * @param { object  } data - Destination data.
   * @param { string  } data.id - destination ID.
   * @param { number  } data.channelId - channel ID.
   * @param { string  } data.name - name.
   * @param { object  } data.channel - The channel object of the destination.
   * @param { object[] } data.sources - The sources of the destination.
   */
  constructor(data) {
    this.id = toNumber(data.id);
    this.channelId = toNumber(data.channelId);
    this.name = data.name;
    this.channel = data.channel || {};
    this.sources = data.sources || [];
  }

  /**
   * Retrieves the destination ID.
   *
   * @return { number } The destination ID.
   */
  getId() {
    return this.id;
  }

  /**
   * Retrieves the channelId.
   *
   * @return { number } The channelId.
   */
  getChannelId() {
    return this.channelId;
  }

  /**
   * Get the name.
   *
   * @return { string } The name.
   */
  getName() {
    return this.name;
  }

  /**
   * Returns a new Channel object based on the current channel.
   *
   * @return { import('./Channel.js').default } A new Channel object.
   */
  getChannel() {
    const Channel = require('./Channel.js').default;
    return new Channel(this.channel);
  }

  /**
   * Retrieves the sources.
   *
   * @return { Source[] } The sources.
   */
  getSources() {
    return this.sources.map((source) => new Source(source));
  }

  isPerformance() {
    return this.name === 'performance';
  }

  isProduct() {
    return this.name === 'product';
  }

  isBasic() {
    return this.name === 'basic';
  }

  isAnalysis() {
    return this.name === 'analysis';
  }

  isEcommerce() {
    return this.name === 'ecommerce';
  }

  isOther() {
    return this.name === 'other';
  }
}
