import * as Sentry from '@sentry/vue';

export default ({ app, router, environment }) => Sentry.init({
  app,
  dsn: 'https://6a4a4c2a47cd42c9a3f09e47d8f229c8@o1376845.ingest.sentry.io/6686645',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'omnicliq-dashboard-v2.onrender.com'],
    }),
  ],
  tracesSampleRate: 1.0,
  environment,
});
