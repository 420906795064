import {
  find,
  toNumber,
  isUndefined,
  isNull,
} from 'lodash';
import ApiService from '../services/ApiService.js';
import { teamRoutes as apiRoutes } from '../helpers/apiRoutes.js';

export default class Team {
  /**
   *
   * @param { Object } data team data
   * @param { Number } data.id id
   * @param { String } data.name name
   * @param { Number|Null } data.departmentId team department id
   * @param { Object|Null } data.department team department
   * @param { Number|Null } data.supervisorId team supervisor id
   * @param { Object|Null } data.supervisor team supervisor
   * @param { Array<Object> } data.members team members
   * @param { Array<Object> } data.managers team managers
   * @param { Array<Object> } data.projects team projects
   */
  // @ts-ignore
  constructor(data = {}) {
    this.id = toNumber(data.id);
    this.name = data.name;
    this.departmentId = toNumber(data.departmentId);
    this.supervisorId = toNumber(data.supervisorId);
    this.department = data.department || {};
    this.supervisor = data.supervisor || {};
    this.members = data.members || [];
    this.managers = data.managers || [];
    this.projects = data.projects || [];
  }

  /**
   * Retrieves the id.
   *
   * @return { number } The id.
   */
  getId() {
    return this.id;
  }

  /**
   * Get the name of the object.
   *
   * @return { string } The name of the object.
   */
  getName() {
    return this.name;
  }

  /**
   * Get department ID.
   *
   * @return { Number|Null } The department ID.
   */
  getDepartmentId() {
    return this.departmentId;
  }

  /**
   * Get team department
   *
   * @returns { object } team department
   */
  getDepartment() {
    return this.department;
  }

  /**
   * Get supervisor ID.
   *
   * @return { number|null } The supervisor ID.
   */
  getSupervisorId() {
    return this.supervisorId;
  }

  /**
   * Get team supervisor.
   *
   * @returns { object } supervisor
   */
  getSupervisor() {
    const User = require('./User.js').default;
    return new User(this.supervisor);
  }

  /**
   * Checks if team has supervisor.
   *
   * @return { boolean } - Returns true if team has supervisor, false otherwise.
   */
  isSupervisorAssigned() {
    return !isUndefined(this.supervisorId) && !isNull(this.supervisorId);
  }

  /**
   * Get team managers
   *
   * @returns { import('./User.js').default[] } team managers
   */
  getManagers() {
    const User = require('./User.js').default;
    return this.managers.map((user) => new User(user));
  }

  /**
   * Get team members
   *
   * @returns { import('./User.js').default[] } team members
   */
  getMembers() {
    const User = require('./User.js').default;
    return this.members.map((user) => new User(user));
  }

  /**
   * Get team projects
   *
   * @returns { import('./Project.js').default[] } projects
   */
  getProjects() {
    const Project = require('./Project.js').default;
    return this.projects.map((project) => new Project(project));
  }

  /**
   *
   *
   * @param { Number|String } userId user id
   * @returns { Boolean } true if user is member of team, otherwise false
   */
  isTeamMember(userId) {
    const user = find(this.members, (member) => toNumber(member.id) === toNumber(userId));
    return !isUndefined(user);
  }

  /**
   * Query all teams with from API
   *
   * @param { object } params - query params
   * @returns { Promise }
   */
  static queryAll(params = {}) {
    return ApiService.get(apiRoutes.getTeams(), { related: true, ...params }).then(({ data }) => data.data);
  }

  /**
   * Query team by id from API
   *
   * @param { number|string } id - team id
   * @param { object } params - query params
   * @returns { Promise }
   */
  static queryById(id, params = {}) {
    return ApiService.get(apiRoutes.getTeamById(id), { related: true, ...params }).then(({ data }) => data.data);
  }

  /**
   * Create a new team via API
   *
   * @param { object } body - body with new team data
   * @returns { Promise }
   */
  static create(body) {
    return ApiService.post(apiRoutes.createTeam(), body).then(({ data }) => data.data);
  }

  /**
   * Update a team by id via API
   *
   * @param { number|string } id - team id
   * @param { object } body - body with team data update
   * @returns { Promise }
   */
  static update(id, body) {
    return ApiService.patch(apiRoutes.updateTeam(id), body).then(({ data }) => data.data);
  }

  /**
   * Delete department by id
   *
   * @param { number|string } id - department id
   * @returns { Promise }
   */
  static delete(id) {
    return ApiService.delete(apiRoutes.deleteTeam(id));
  }
}
