import path from 'path-browserify';
import { useStore } from 'vuex';

export const getIllustrationsPath = (illustrationName) => `media/illustrations/${useStore()
  .getters.layoutConfig('illustrations.set')}/${illustrationName}`;

/**
 *
 * @param { String|null|undefined } resourcePath resource path than needs url
 * @returns { String } url string
 */
export const buildServerAssetUrl = (resourcePath) => {
  if (!resourcePath) {
    return '';
  }
  const assetsPath = path.join('public', resourcePath);
  const url = new URL(assetsPath, process.env.VUE_APP_API_SERVER_URL);
  return url.href;
};

export const getCountriesData = () => ([
  {
    flagIconPath: 'media/flags/greece.svg',
    code: '30',
    name: 'Greece',
  },
  {
    flagIconPath: 'media/flags/uk.svg',
    code: '44',
    name: 'UK',
  },
]);
