export default {
  state: {
    breadcrumbs: {},
  },
  getters: {
    breadcrumbs(state) {
      return state.breadcrumbs;
    },
    pageBreadcrumbPath(state) {
      return state.breadcrumbs.pageBreadcrumbPath;
    },
    pageTitle(state) {
      return state.breadcrumbs.title;
    },
  },
  mutations: {
    setBreadcrumbMutation(state, payload) {
      state.breadcrumbs = payload;
    },
  },
  actions: {
    setBreadcrumbAction({ commit }, payload) {
      commit('setBreadcrumbMutation', payload);
    },
  },
};
