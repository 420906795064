import Permissions from '@parthar/rbac/lib/Permissions.js';
import { toNumber } from 'lodash';

export default class Role {
  constructor(roleData) {
    const {
      id,
      name,
      usersAccess = [],
      teamsAccess = [],
      departmentsAccess = [],
      positionsAccess = [],
      rolesAccess = [],
      logsAccess = [],
      projectsAccess = [],
      invoicesAccess = [],
      accountsAccess = [],
    } = roleData;

    this.id = toNumber(id);
    this.name = name;
    this.permissions = new Permissions()
      .resource('users')
      .actions(usersAccess)
      .resource('teams')
      .actions(teamsAccess)
      .resource('departments')
      .actions(departmentsAccess)
      .resource('positions')
      .actions(positionsAccess)
      .resource('roles')
      .actions(rolesAccess)
      .resource('logs')
      .actions(logsAccess)
      .resource('projects')
      .actions(projectsAccess)
      .resource('invoices')
      .actions(invoicesAccess)
      .resource('accounts')
      .actions(accountsAccess);
  }

  /**
   * Checks if the user has permission to perform the given actions on the specified resource.
   *
   * @param { string}  resource - The resource on which the actions are being performed.
   * @param { Array.<'create'|'read'|'update'|'delete'> } actions - An array of actions to be performed on the resource.
   * @return { boolean } Returns true if the user has permission to perform all the actions, otherwise false.
   */
  can(resource, actions) {
    if (actions.length === 0) {
      return false;
    }
    return actions.every((action) => this.permissions.can(resource, action));
  }

  /**
   * Get role id.
   *
   * @return { number } role id
   */
  getId() {
    return this.id;
  }

  /**
   * Get role name
   *
   * @return { string } role name
   */
  getName() {
    return this.name;
  }
}
