export default {
  themeName: 'Omnicliq',
  themeVersion: '1.0.0',
  main: {
    type: 'default',
    primaryColor: '#009EF7',
    logo: {
      dark: 'media/logos/logo.svg',
      light: 'media/logos/leon-logo.svg',
    },
  },
  illustrations: {
    set: 'sigma-1',
  },
  loader: {
    logo: 'media/logos/logo-1-dark.svg',
    display: true,
    type: 'default',
  },
  scrollTop: {
    display: true,
  },
  header: {
    display: true,
    menuIcon: 'font',
    width: 'fluid',
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  toolbar: {
    display: true,
    width: 'fluid',
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  aside: {
    display: true,
    theme: 'dark',
    fixed: true,
    menuIcon: 'font',
    minimized: false,
    minimize: true,
    hoverable: true,
  },
  content: {
    width: 'fixed',
  },
  footer: {
    width: 'fluid',
  },
};
