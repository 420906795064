import { sumBy, toNumber, round } from 'lodash';
import moment from 'moment';
import OutcomeMonthMap from './OutcomeMonthMap.js';

export default class OutcomeYear {
  /**
   *
   * @param { Object } data - year data
   * @param { Number } data.year - year number
   * @param { Number= } data.outcome - analytics project outcome
   * @param { Number= } data.actualOutcome - actual project outcome
   *  (that calculated base of manual updates and analytics outcome)
   * @param { Array= } data.months - array of year months
   */
  constructor({
    year,
    outcome = 0,
    actualOutcome = 0,
    months = [],
  }) {
    const numberRound = toNumber(process.env.VUE_APP_NUMBER_ROUND) || 2;

    this.yearNumber = toNumber(year);
    this.outcome = round(toNumber(outcome), numberRound);
    this.actualOutcome = round(toNumber(actualOutcome), numberRound);
    this.months = new OutcomeMonthMap(months, this);
  }

  /**
   *
   * @returns { Number } - year number
   */
  getOrderNumber() {
    return this.yearNumber;
  }

  /**
   *
   * @returns { Number } - analycits project outcome
   */
  getAnalyticsOutcome() {
    return this.outcome;
  }

  /**
   *
   * @returns { Number } - actual project outcome
   */
  getActualOutcome() {
    return this.actualOutcome;
  }

  /**
   *
   * @returns { Number } - actual project outcome to date
   */
  getOutcomeToDate() {
    const [currentMonthNumber, currentDayNumber] = moment().format('M-D').split('-');
    const monthsOutcome = this.getMonths()
      .getMonthsList()
      .filter((month) => month.getOrderNumber() <= toNumber(currentMonthNumber))
      .map((month) => {
        if (month.getOrderNumber() !== toNumber(currentMonthNumber)) {
          return month.getActualOutcome();
        }
        const days = month
          .getDays()
          .getDaysList()
          .filter((day) => day.getOrderNumber() <= toNumber(currentDayNumber));
        return sumBy(days, (day) => day.getActualOutcome());
      });

    return sumBy(monthsOutcome);
  }

  /**
   *
   * @returns { OutcomeMonthMap } - months map
   */
  getMonths() {
    return this.months;
  }

  /**
   *
   * @returns { Boolean } - is year's outcome is completed (all months have outcome)
   */
  isCompleteOutcome() {
    const yearMonthsCount = 12;
    return this.months.getCompleteOutcomeMonthsCount() === yearMonthsCount;
  }
}
