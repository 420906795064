<template>
  <el-tooltip
    :content="tooltipContent"
    placement="top-start"
    :effect="isCopied ? 'customized' : 'light'"
  >
    <div
      ref="target"
      class="cursor-pointer copy d-flex align-items-center"
      :class="{...customClasses}"
      @click="copy(text)"
    >
      <slot />
      <el-icon
        v-if="!isCopied"
        :size="17"
        class="ms-2 copy-icon"
      >
        <CopyDocument />
      </el-icon>
      <el-icon
        v-else
        :size="17"
        class="ms-2 copy-icon text-success"
      >
        <Checked />
      </el-icon>
    </div>
  </el-tooltip>
</template>

<script setup>
import { computed } from 'vue';
import { useClipboard } from '@vueuse/core';
import { CopyDocument, Checked } from '@element-plus/icons-vue';

const props = defineProps({
  customClasses: {
    type: Object,
    default() {
      return {};
    },
  },
  text: {
    type: [String, Number],
    default: '',
  },
});
const {
  copy, copied: isCopied,
} = useClipboard({ source: props.text });

const tooltipContent = computed(() => (isCopied.value ? 'Copied!' : 'Click to copy!'));
</script>

<style lang="scss">
.copy > .copy-icon {
  visibility: hidden;
}

.copy:hover {
  .copy-icon {
    visibility: visible;
  }
}

.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  color: #47BE7D;
  background: #E8FFF3;
}

.el-popper.is-customized .el-popper__arrow::before {
  background: #E8FFF3;
  right: 0;
}
</style>
