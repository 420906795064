export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    isLoading: (state) => state.loading,
  },
  mutations: {
    activateLoading(state) {
      state.loading = true;
    },
    deactivateLoading(state) {
      state.loading = false;
    },
  },
};
