import { toNumber } from 'lodash';
import ChannelSpendDayMap from './ChannelSpendDayMap.js';

export default class SpendMonth {
  /**
   *
   * @param { Object } data spend month data
   * @param { Number|String } data.year month's year order number
   * @param { Number|String } data.month month order number
   * @param { Number|String } data.target month spend target
   * @param { Number|String } data.spend month spend
   * @param { Array } data.days month spend days
   */
  constructor(data) {
    this.monthOrderNumber = toNumber(data.month);
    this.monthYearOrderNumber = toNumber(data.year);
    this.target = toNumber(data.target || 0);
    this.spend = toNumber(data.spend || 0);
    this.days = new ChannelSpendDayMap(data.days || []);
  }

  /**
   * @returns { Number } month ourder number
   */
  getMonthOrderNumber() {
    return this.monthOrderNumber;
  }

  /**
     * @returns { Number } month spend
     */
  getSpend() {
    return this.spend;
  }

  /**
     * @returns { Number } month target
     */
  getTarget() {
    return this.target;
  }

  /**
   *
   * @returns { ChannelSpendDayMap }
   */
  getDays() {
    return this.days;
  }

  /**
     *
     * @returns { Number } month deviation in percentages
     */
  getDeviationInPercentages() {
    return this.target === 0 ? 0 : (this.spend / this.target) * 100;
  }
}
