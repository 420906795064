import { toNumber, cloneDeep } from 'lodash';
import Project from '../../core/models/Project.js';
import { projectRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    projects: new Map(),
  },
  getters: {
    getProjects: (state) => () => [...state.projects.values()].map((project) => new Project(cloneDeep(project))),
    /**
     * Retrieves a project by its ID.
     *
     * @return { (id) => Project } The project object.
     */
    getProjectById: (state) => (id) => {
      const project = state.projects.get(toNumber(id)) || { id };
      return new Project(cloneDeep(project));
    },
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = new Map(projects.map((project) => [toNumber(project.id), project]));
    },
    addProject(state, project) {
      state.projects.set(toNumber(project.id), project);
    },
    deleteProjectById(state, id) {
      state.projects.delete(toNumber(id));
    },
    clearStore(state) {
      state.projects.clear();
    },
  },
  actions: {
    async loadProjects({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getProjects(), { includeRelations: true, ...params });
      commit('setProjects', data.data);
    },
    async loadProjectById({ commit }, { id, params = {} }) {
      const { data } = await apiService.get(apiRoutes.getProjectById(id), { includeRelations: true, ...params });
      commit('addProject', data.data);
    },
    async createProject({ dispatch }, project) {
      const { data } = await apiService.post(apiRoutes.createProject(), project);
      dispatch('loadProjectById', { id: data.data.id });
    },
    async updateProject({ dispatch }, { id, body }) {
      await apiService.patch(apiRoutes.updateProject(id), body);
      dispatch('loadProjectById', { id });
    },
    async pauseProject({ dispatch }, id) {
      await apiService.patch(apiRoutes.pauseProject(id));
      dispatch('loadProjectById', { id });
    },
    async activateProject({ dispatch }, id) {
      await apiService.patch(apiRoutes.activateProject(id));
      dispatch('loadProjectById', { id });
    },
    async deleteProject({ commit }, id) {
      await apiService.delete(apiRoutes.deleteProject(id));
      commit('deleteProjectById', id);
    },
  },
});
