import {
  last,
  orderBy,
  toNumber,
  round,
} from 'lodash';

export default class OutcomeDay {
  /**
   *
   * @param { Object } data - day data
   * @param { Number } data.day - day order number
   * @param { Number= } data.outcome - analytics project outcome
   * @param { Number= } data.actualOutcome - project outcome manually updated
   * @param { Array= } data.updates - outcome updates
   * @param { import('./OutcomeMonth.js').default } month - days month
   */
  constructor({
    day,
    outcome = 0,
    actualOutcome = 0,
    updates = [],
  }, month) {
    const numberRound = toNumber(process.env.VUE_APP_NUMBER_ROUND);

    this.month = month;
    this.dayOrderNumber = day;
    this.outcome = round(toNumber(outcome), numberRound);
    this.actualOutcome = round(toNumber(actualOutcome), numberRound);
    this.updates = orderBy(updates, 'dateUpdate');
  }

  /**
   *
   * @returns { Number } - day order number
   */
  getOrderNumber() {
    return this.dayOrderNumber;
  }

  /**
   *
   * @returns { import('./OutcomeMonth.js').default } - days month
   */
  getMonth() {
    return this.month;
  }

  /**
   *
   * @returns { Number } - analytics outcome
   */
  getAnalyticsOutcome() {
    return this.outcome;
  }

  /**
   *
   * @returns { Number } - actual outcome (base on manual updates)
   */
  getActualOutcome() {
    return this.actualOutcome;
  }

  /**
   *
   * @returns
   */
  getLastOutcomeUpdate() {
    const lastUpdate = last(this.updates) || {};
    return lastUpdate.outcome;
  }

  isOutcomeUpdated() {
    return !!this.getLastOutcomeUpdate();
  }
}
