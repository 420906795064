/**
 * Project statuses.
 * @readonly
 * @enum { string }
 */
export const PROJECT_STATUSES = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  DELETED: 'deleted',
};

/**
 * Project types.
 * @readonly
 * @enum { string }
 */
export const PROJECT_TYPES = {
  LEAD_GENERATION: 'Lead Gen',
  ECOMMERCE: 'Ecommerce',
};

/**
 * Project growing phases.
 * @readonly
 * @enum { string }
 */
export const PROJECT_GROWING_PHASES = {
  GROWING: 'growing',
  MATURE: 'mature',
  BOARDING: 'boarding',
};

/**
 * Account statuses.
 * @readonly
 * @enum { string }
 */
export const ACCOUNT_STATUSES = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  DELETED: 'deleted',
};
