import { toLower, toNumber } from 'lodash';
import dayjs from 'dayjs';
import { buildServerAssetUrl } from '../helpers/assets.js';

export default class Client {
  /**
   *
   * @param { Object } data - client data
   * @param { Number } data.id client id
   * @param { String } data.name client name
   * @param { String } data.createdAt client's date of created
   * @param { String|Null } data.updatedAt client's date of updated
   * @param { String|Null } data.iconPath icon path
   * @param { Array<Object> } data.projects client projects
   * @param { Array<Object> } data.users client users
   * @param { String|Null } data.iconPath icon path
   * @param { String } data.description - description
   * @param { String } data.email - contact email
   * @param { String } data.status - status
   */
  // @ts-ignore
  constructor(data = {}) {
    this.id = toNumber(data.id);
    this.name = data.name;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.iconPath = data.iconPath || '';
    this.projects = data.projects || [];
    this.users = data.users || [];
    this.description = data.description || '';
    this.email = data.email;
    this.status = data.status;
  }

  /**
   * Get the ID of the client.
   *
   * @return { number } The ID of the client.
   */
  getId() {
    return this.id;
  }

  /**
   * Get the name of the client.
   *
   * @return { string } The name of the client.
   */
  getName() {
    return this.name;
  }

  /**
   * Returns the date of creation.
   *
   * @return { import('dayjs').Dayjs|null} The date of creation.
   */
  getDateOfCreating() {
    return this.createdAt ? dayjs(this.createdAt) : null;
  }

  /**
   * Returns the date of updating.
   *
   * @return  { import('dayjs').Dayjs|null } The date of updating.
   */
  getDateOfUpdating() {
    return this.updatedAt ? dayjs(this.updatedAt) : null;
  }

  /**
   * Returns the icon path for the current client.
   *
   * @return { string } The icon path for the current client.
   */
  getIconPath() {
    return buildServerAssetUrl(this.iconPath);
  }

  /**
   * Get the email of the client.
   *
   * @return { string|null } The email of the client.
   */
  getEmail() {
    return this.email;
  }

  /**
   * Get the description of the client.
   *
   * @return { string|null } The description of the client.
   */
  getDescription() {
    return this.description;
  }

  /**
   * Get the status of the client.
   *
   * @return { string } The status of the client.
   */
  getStatus() {
    return this.status;
  }

  /**
   * Retrieves the clint projects.
   *
   * @return { import('./Project.js').default[] } An array of projects.
   */
  getProjects() {
    const Project = require('./Project.js').default;
    return this.projects.map((project) => new Project(project));
  }

  /**
   * Retrieves the list of users.
   *
   * @return { import('./User.js').default[] } An array of the users.
   */
  getUsers() {
    const User = require('./User.js').default;
    return this.users.map((user) => new User(user));
  }

  /**
   * Returns the count of users.
   *
   * @return { number } The count of users.
   */
  getUsersCount() {
    return this.users.length;
  }

  /**
   * Checks if the client has any projects.
   *
   * @return { boolean } Returns true if the client has projects, false otherwise.
   */
  hasProjects() {
    return this.projects.length > 0;
  }

  /**
   * Returns the count of projects.
   *
   * @return { number } The count of projects.
   */
  getProjectsCount() {
    return this.projects.length;
  }

  /**
   * Checks if client has email.
   *
   * @return { boolean } Returns true if the email exists, otherwise false.
   */
  hasEmail() {
    return !!this.email;
  }

  /**
   * Determines if the client is 'active'.
   *
   * @return { boolean } Returns true if the status is 'active', false otherwise.
   */
  isActive() {
    return toLower(this.status) === 'active';
  }

  /**
   * Checks if the status is 'paused'.
   *
   * @return { boolean } Returns true if the status is 'paused', otherwise false.
   */
  isPaused() {
    return toLower(this.status) === 'paused';
  }

  /**
   * Checks if the status is 'deleted'.
   *
   * @return { boolean } Returns true if the status is 'deleted', otherwise false.
   */
  isDeleted() {
    return toLower(this.status) === 'deleted';
  }
}
