const getObjectPropertyValueByKey = (obj, key) => {
  const map = new Map(Object.entries(obj));
  if (Object.prototype.hasOwnProperty.call(obj, key) && map) {
    return map.get(key);
  }
};

/**
 * Generates unique ID for give prefix.
 * @param {string} prefix Prefix for generated ID
 * @returns {boolean}
 */
const getUniqueIdWithPrefix = (prefix) => {
  const result = Math.floor(Math.random() * new Date().getTime()).toString();
  if (!prefix) {
    return result;
  }

  return `${prefix}${result}`;
};

const stringSnakeToCamel = (str) => str.replace(/(\-\w)/g, (m) => {
    return m[1].toUpperCase();
  });

const toJSON = (value) => {
  if (typeof value !== 'string') {
    return value;
  }

  if (!value) {
    return;
  }

  // ("'" => "\"");
  const result = value
    .toString()
    .split('')
    .map((el) => (el !== "'" ? el : '"'))
    .join('');
  const jsonStr = result.replace(/(\w+:)|(\w+ :)/g, (matched) => {
    return `"${matched.substring(0, matched.length - 1)}":`;
  });

  try {
    return JSON.parse(jsonStr);
  } catch {
    return;
  }
};

export {
  getObjectPropertyValueByKey,
  getUniqueIdWithPrefix,
  stringSnakeToCamel,
  toJSON,
};
