import { toNumber } from 'lodash';

export default (apiService) => ({
  namespaced: true,
  state: {
    events: new Map(),
    eventSteps: new Map(),
  },
  getters: {
    getEvents(state) {
      return [...state.events.values()];
    },
    getEventSteps: (state) => (eventId) => [...state.eventSteps.values()]
      .filter((step) => step.eventId === toNumber(eventId)),
    getEventById: (state) => (id) => state.events.get(toNumber(id)),
  },
  mutations: {
    initStore(state, events = []) {
      const eventsEntries = events
        .map((event) => ([toNumber(event.id), event]));
      state.events = new Map(eventsEntries);
    },
    addEvent(state, event) {
      state.events.set(toNumber(event.id), event);
    },
    addEventSteps(state, steps) {
      steps.forEach((step) => state.eventSteps.set(toNumber(step.id), step));
    },
    deleteEvent(state, id) {
      state.events.delete(toNumber(id));
    },
    clearStore(state) {
      state.events = new Map();
      state.eventSteps = new Map();
    },
  },
  actions: {
    async loadEvents({ commit }) {
      const { data } = await apiService.get('/events', { related: true });
      const events = data.data;
      commit('clearStore');
      commit('initStore', events);
      return events;
    },
    async loadEventById({ commit }, id) {
      const { data } = await apiService.get(`/events/${id}`);
      const event = data.data;
      commit('addEvent', event);
      return event;
    },
    async loadEventSteps({ commit }, eventId) {
      const { data } = await apiService.get(`/events/${eventId}/steps`);
      const steps = data.data;
      commit('addEventSteps', steps);
      return steps;
    },
  },
});
