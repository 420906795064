import { toNumber, sumBy } from 'lodash';
import TargetMonthMap from './PerformanceTargetMonthMap.js';

export default class TargetYear {
  /**
   *
   * @param {{ year: Number|String, target: Number|String, months: Array }} data - target year data
   */
  constructor({ year, target, months = [] }) {
    this.yearOrderNumber = toNumber(year);
    this.target = toNumber(target) || 0;
    this.months = new TargetMonthMap(months, this);
  }

  /**
   *
   * @returns { Number } yaer number
   */
  getOrderNumber() {
    return this.yearOrderNumber;
  }

  /**
   *
   * @returns { Number } year target
   */
  getTarget() {
    return this.target;
  }

  /**
   *
   * @returns { Number } year target up to date
   */
  getTargetUpToDate() {
    const currentTargetMonth = this.months.getCurrentMonth();
    const monthsUpToCurrentMonth = this.months.getMonthsList()
      .filter((month) => month.getOrderNumber() < currentTargetMonth.getOrderNumber());
    const targetUpToCurrentMonth = sumBy(monthsUpToCurrentMonth, (month) => month.getTarget());
    return currentTargetMonth.getTargetUpToDate() + targetUpToCurrentMonth;
  }

  /**
   *
   * @param { Number|String } monthNumber
   * @returns { import('./PerformanceTargetMonth.js').default } target month
   */
  getMonth(monthNumber) {
    return this.months.getMonth(toNumber(monthNumber));
  }

  /**
   *
   * @returns { TargetMonthMap } current year months map
   */
  getMonths() {
    return this.months;
  }

  isCompleteKpi() {
    return this.months.isCompleteMonthsKpis();
  }

  isComplete() {
    // a month exist in months list if its target have been defined
    const monthsCount = 12;
    return this.months.getMonthsList().length === monthsCount;
  }

  hasTarget() {
    return this.target > 0;
  }
}
