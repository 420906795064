import dayjs from 'dayjs';
import { toNumber } from 'lodash';

import FinancialYear from './FinancialYear.js';
import PerformanceTarget from '../target/PerformanceTarget.js';
import BrandingTarget from '../target/BrandingTarget.js';
import Outcome from '../outcome/Outcome.js';
import Spend from '../spend/Spend.js';

export default class Financials {
  /**
   *
   * @param {{
   *  projectId: Number|String,
   *  performanceTarget: Array,
   *  brandingTarget: Array,
   *  outcome: Array,
   *  spend: Array,
   *  }} data - financials data
   */
  constructor({
    projectId,
    performanceTarget = [],
    brandingTarget = [],
    outcome = [],
    spend = [],

  }) {
    this.projectId = toNumber(projectId);
    this.performanceTarget = new PerformanceTarget({ projectId, target: performanceTarget });
    this.brandingTarget = new BrandingTarget({ projectId, target: brandingTarget });
    this.outcome = new Outcome({ projectId, outcome });
    this.spend = new Spend({ projectId, spend });
  }

  /**
   *
   * @returns { Number } project id
   */
  getProjectId() {
    return this.projectId;
  }

  /**
   *
   * @param { Number|String } yearOrderNumber - year order number
   * @returns
   */
  getYear(yearOrderNumber) {
    const normalizedYearOrderNumber = toNumber(yearOrderNumber);

    const performanceTargetYear = this.performanceTarget.getYear(normalizedYearOrderNumber);
    const brandingTargetYear = this.brandingTarget.getYear(normalizedYearOrderNumber);
    const outcomeYear = this.outcome.getYear(normalizedYearOrderNumber);
    const spendYear = this.spend.getYear(normalizedYearOrderNumber);

    return new FinancialYear({
      performanceTargetYear,
      brandingTargetYear,
      outcomeYear,
      spendYear,
      yearOrderNumber: normalizedYearOrderNumber,
    });
  }

  /**
   * Retrieves the current year.
   *
   * @return { FinancialYear } The current year.
   */
  getCurrentYear() {
    const currentYearNumber = dayjs().get('year');
    return this.getYear(currentYearNumber);
  }

  /**
   * Returns the previous year.
   *
   * @return { FinancialYear } The previous year number.
   */
  getPreviousYear() {
    const previousYearNumber = dayjs().subtract(1, 'year').get('year');
    return this.getYear(previousYearNumber);
  }
}
