import { getUniqueIdWithPrefix } from './typesHelpers.js';

export default class DOMEventHandlerUtil {
  static store = new Map();

  static on(
    element,
    selector,
    eventName,
    callBack,
  ) {
    const eventId = getUniqueIdWithPrefix('DOMEvent');
    DOMEventHandlerUtil.store.set(eventId, (event) => {
      const targets = element.querySelectorAll(selector);
      let target = event.target;

      while (target && target !== element) {
        for (let i = 0; i < targets.length; i += 1) {
          if (target === targets[i]) {
            callBack(target, event);
          }
        }

        if (target.parentElement) {
          target = target.parentElement;
        } else {
          target = null;
        }
      }
    });

    element.addEventListener(eventName, DOMEventHandlerUtil.store.get(eventId));
    return eventId;
  }

  static off(element, eventName, eventId) {
    const funcFromStore = DOMEventHandlerUtil.store.get(eventId);
    if (!funcFromStore) {
      return;
    }

    element.removeEventListener(eventName, funcFromStore);
    DOMEventHandlerUtil.store.delete(eventId);
  }

  static one(element, eventName, callBack) {
    element.addEventListener(eventName, function calee(event) {
      // remove event
      if (event.target && event.target.removeEventListener) {
        event.target.removeEventListener(event.type, calee);
      }

      if (element && event && event.currentTarget) {
        event.currentTarget.removeEventListener(event.type, calee);
      }

      // call hander
      return callBack(event);
    });
  }
}
