import dayjs from 'dayjs';
import { toNumber } from 'lodash';
import TargetYear from './BrandingTargetYear.js';

export default class BrandingTarget {
  years = new Map();

  /**
   *
   * @param {{ projectId: Number|String, target: Array }} data - target data
   */
  constructor({ projectId, target = [] }) {
    this.projectId = toNumber(projectId);
    this.years = new Map(target.map((year) => [toNumber(year.year), new TargetYear(year)]));
  }

  /**
   *
   * @returns { Number } project id
   */
  getProjectId() {
    return this.projectId;
  }

  /**
  * @param { Number|String } yearOrderNumber - year number
  * @returns { TargetYear } target year
  */
  getYear(yearOrderNumber) {
    return this.years.get(toNumber(yearOrderNumber)) || new TargetYear({ year: yearOrderNumber });
  }

  /**
   *
   * @returns { TargetYear[] } target years list
   */
  getYearsList() {
    return [...this.years.values()];
  }

  /**
   * Gets the current year instance.
   *
   * @return { TargetYear } current year.
   */
  getCurrentYear() {
    return this.years.get(dayjs().year());
  }
}
