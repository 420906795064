import { toNumber, cloneDeep } from 'lodash';
import User from '../../core/models/User.js';
import { userRoutes as apiRoutes } from '../../core/helpers/apiRoutes.js';

export default (apiService) => ({
  namespaced: true,
  state: {
    users: new Map(),
  },
  getters: {
    getUsers: (state) => () => [...state.users.values()].map((user) => new User(cloneDeep(user))),
    getUserById: (state) => (id) => {
      const user = state.users.get(toNumber(id));
      return user ? new User(cloneDeep(user)) : new User({ id });
    },
  },
  mutations: {
    setUsers(state, users) {
      state.users = new Map(users.map((user) => [toNumber(user.id), user]));
    },
    addUser(state, user) {
      state.users.set(toNumber(user.id), user);
    },
    deleteUserById(state, id) {
      state.users.delete(toNumber(id));
    },
    clearStore(state) {
      state.users.clear();
    },
  },
  actions: {
    /**
     * Loads users from the API and stores them in the store.
     *
     * @param { Object } commit - The Vuex commit function.
     */
    async loadUsers({ commit }, params = {}) {
      const { data } = await apiService.get(apiRoutes.getUsers(), { related: true, ...params });
      const users = data.data.filter((user) => user.id !== 1);
      commit('setUsers', users);
    },
    /**
     * Loads user by id from the API and stores it in the store.
     *
     * @param { object } commit - The Vuex commit function.
     * @param { object } data - The data to load user.
     * @param { number|string } data.id - The user id to load.
     * @param { object } data.params - The query parameters.
     */
    async loadUserById({ commit }, { id, params = {} }) {
      const { data } = await apiService.get(apiRoutes.getUserById(id), { related: true, ...params });
      commit('addUser', data.data);
    },
    async deleteUserById({ commit }, id) {
      await apiService.delete(apiRoutes.deleteUserById(id));
      commit('deleteUserById', id);
    },
    async updateUserById({ dispatch }, { id, body }) {
      await apiService.patch(apiRoutes.updateUserById(id), body);
      dispatch('loadUserById', { id });
    },
    async createUser({ dispatch }, body) {
      const { data } = await apiService.post(apiRoutes.createUser(), body);
      dispatch('loadUserById', data.data.id);
    },
    async updateUserEmail({ dispatch }, { id, body }) {
      await apiService.patch(apiRoutes.updateUserEmail(id), body);
      dispatch('loadUserById', { id });
    },
    async updateUserPassword({ dispatch }, { id, body }) {
      apiService.patch(apiRoutes.updateUserPassword(id), body);
      dispatch('loadUserById', { id });
    },
  },
});
