import dayjs from 'dayjs';
import { round, range, toNumber } from 'lodash';
import TargetDay from './PerformanceTargetDay.js';

export default class TargetDayMap {
  days = new Map();

  /**
   *
   * @param { import('./PerformanceTargetMonth.js').default } month - days month
   */
  constructor(month) {
    const countMonthDays = dayjs().set('month', month.getOrderNumber() - 1).daysInMonth();
    const dailyTarget = round(month.getTarget() / countMonthDays, 2);
    const days = range(1, countMonthDays + 1)
      .map((dayOrderNumber) => ({ dayOrderNumber, target: dailyTarget }));
    this.days = new Map(days.map((day) => ([day.dayOrderNumber, new TargetDay(day, month)])));
    this.daysInMonth = countMonthDays;
  }

  /**
   *
   * @param { Number|String } dayOrderNumber
   * @returns { TargetDay } return target day for given day order number
   */
  getDay(dayOrderNumber) {
    const normalizedMonthOrderNumber = toNumber(dayOrderNumber);
    if (normalizedMonthOrderNumber < 1 || normalizedMonthOrderNumber > this.daysInMonth) {
      throw new Error(`Invalid day order number - ${normalizedMonthOrderNumber}`);
    }
    return this.days.get(toNumber(normalizedMonthOrderNumber));
  }

  /**
   *
   * @returns { TargetDay[] } target days
   */
  getDaysList() {
    return [...this.days.values()];
  }

  /**
   *
   * @param { Number|String } dayOrderNumber
   * @returns { Boolean }
   */
  hasDay(dayOrderNumber) {
    return this.days.has(toNumber(dayOrderNumber));
  }

  /**
   *
   * @returns { TargetDay } current day target
   */
  getCurrentDay() {
    const currentDayNumber = dayjs().get('D');
    return this.days.get(currentDayNumber);
  }
}
