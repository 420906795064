import { AbilityBuilder } from '@casl/ability';

export default (roleAccess, userAccess) => {
  const { can, rules } = new AbilityBuilder();

  const {
    usersAccess = [],
    teamsAccess = [],
    departmentsAccess = [],
    positionsAccess = [],
    rolesAccess = [],
    logsAccess = [],
    projectsAccess = [],
    invoicesAccess = [],
    clientsAccess = [],
    accountsAccess = [],
  } = roleAccess;

  [...usersAccess, ...userAccess.usersAccess || []].forEach((ability) => can(ability, 'users'));
  [...teamsAccess, ...userAccess.teamsAccess || []].forEach((ability) => can(ability, 'teams'));
  [...departmentsAccess, ...userAccess.departmentsAccess || []].forEach((ability) => can(ability, 'departments'));
  [...positionsAccess, ...userAccess.positionsAccess || []].forEach((ability) => can(ability, 'positions'));
  [...rolesAccess, ...userAccess.rolesAccess || []].forEach((ability) => can(ability, 'roles'));
  [...logsAccess, ...userAccess.logsAccess || []].forEach((ability) => can(ability, 'logs'));
  [...projectsAccess, ...userAccess.projectsAccess || []].forEach((ability) => can(ability, 'projects'));
  [...invoicesAccess, ...userAccess.invoicesAccess || []].forEach((ability) => can(ability, 'invoices'));
  [...clientsAccess, ...userAccess.clientsAccess || []].forEach((ability) => can(ability, 'clients'));
  [...accountsAccess, ...userAccess.accountsAccess || []].forEach((ability) => can(ability, 'accounts'));

  return rules;
};
