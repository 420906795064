import dayjs from 'dayjs';
import { toNumber, range } from 'lodash';
import OutcomeMonth from './OutcomeMonth.js';

export default class OutcomeMonthMap {
  /**
   *
   * @param { Array } outcomeData - months
   * @param { import('./OutcomeYear.js').default } year - months year
   */
  constructor(outcomeData, year) {
    this.year = year;
    this.months = new Map(outcomeData
      .map((month) => ([toNumber(month.month), new OutcomeMonth(month, year)])));
  }

  /**
   *
   * @param { Number|String } monthOrderNumber - month order number
   * @returns { OutcomeMonth } - outcome month instance
   */
  getMonth(monthOrderNumber) {
    const orderNumber = toNumber(monthOrderNumber);
    const defaultMonth = new OutcomeMonth({ month: orderNumber }, this.year);
    return this.months.get(orderNumber) || defaultMonth;
  }

  /**
   *
   * @returns { OutcomeMonth } - outcome month instance
   */
  getCurrentMonth() {
    const currentMonthNumber = dayjs().get('month') + 1;
    return this.months.get(currentMonthNumber) || new OutcomeMonth({ month: currentMonthNumber }, this.year);
  }

  /**
   *
   * @returns { OutcomeMonth[] } - array of months with complete outcome (all days have outcome)
   */
  getCompleteOutcomeMonthsList() {
    return [...this.months.values()].filter((month) => month.isCompleteOutcome());
  }

  /**
   *
   * @returns { Number } - count of complete outcome months
   */
  getCompleteOutcomeMonthsCount() {
    return this.getCompleteOutcomeMonthsList().length;
  }

  /**
   *
   * @returns { OutcomeMonth[] } - array of months
   */
  getMonthsList() {
    const monthCount = 12;
    return range(1, monthCount + 1).map((orderNumber) => this.getMonth(orderNumber));
  }
}
